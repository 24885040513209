import { Pipe, PipeTransform } from '@angular/core';
import { CountingMovsType } from '../common/enums';
@Pipe({
  name: 'userProgress'
})
export class UserProgressPipe implements PipeTransform {

  transform(value: number): unknown {
    switch (value) {
      case CountingMovsType.Descarga:
        return 'Descargado';
      case CountingMovsType.Conteo:
        return 'Contado';
      case CountingMovsType.Revision:
        return 'Revisado';
      default:
        return 'Asignado';
    }
  }

}
