<!-- <div>
    <div (mouseleave)="showDropDown = false">
        <button class="drop-toggle btn flat" (click)="showDropDown=!showDropDown">
            <span *ngIf="checkedList.length<=0">{{title}}</span>
            <span *ngIf="checkedList.length>0">{{checkedList.join(', ')}}</span>
            <i class="fa fa-angle-down"></i>
        </button>
        <div class="drop-show" *ngIf="showDropDown">
            <label class="drop-item-container" *ngFor="let a of list">
                <input class="input-chck " type="checkbox" [(ngModel)]="a.IsSelected"
                    (change)="getSelectedValue(a.IsSelected,a.Option,a.ParameterId)" /> &nbsp;
                <span>{{a.Option}}</span>
            </label>
        </div>
    </div>
</div> -->







<div class="container">
    <div class="row">

        <div class="input-group" >

            <input placeholder="Busqueda de opciones" (selectItem)="OnSelectItem($event,input)" id="typeahead-focus"
                #input type="text" class="form-control" [placement]="['bottom-left']" [ngbTypeahead]="search"
                (focus)="focus$.next($any($event).target.value)" (click)="click$.next($any($event).target.value)"
                #instance="ngbTypeahead" />

            <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="AddAll()" >Agregar todos</button>
                <button class="btn btn-outline-secondary" type="button" (click)="RemoveAll()" >Remover todos</button>
            </div>
        </div>











        <div style="height: 30vh;overflow: auto; width: 100%; margin-top: 2%;">
            <table *ngIf="GetSelectedList().length>0" class="table table-striped">
                <thead style="background-color: rgb(128,188,91); color: white; ">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col" class="text-center">Eliminar</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let parameter of GetSelectedList(); index as i">
                        <th scope="row">{{i+1}}</th>
                        <td>{{parameter.Option}}</td>
                        <td style="text-align: center;"><a (click)="OnclickRemoveParam(parameter.Option)"
                                class="fa fa-trash"></a></td>

                    </tr>
                </tbody>


            </table>

        </div>


    </div>
</div>