import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { QueryObjectType, QueryParameterType } from 'src/app/common/enums';
import {
  Parameter,
  Query,
  QueryParameter2,
  QueryParameterValidValue,
} from 'src/app/models';
import { OpenCounting } from 'src/app/models/other/OpenCounting';
import { AlertService, QueriesService } from 'src/app/services';
@Component({
  selector: 'app-qry-params',
  templateUrl: './qry-params.component.html',
  styleUrls: ['./qry-params.component.scss'],
  providers: [DatePipe],
})
export class QryParamsComponent implements OnInit {
  queryForm: UntypedFormGroup;
  queries: Query[];
  parameters: QueryParameter2[];
  constructor(
    private modalService: NgbModal,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private queryService: QueriesService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.queryForm = this.formBuilder.group({
      QueryId: [null, Validators.required],
    });
    this.queries = [];
    this.parameters = [];
    this.getQueries();
  }

  formControls(formGroupName: string) {
    return this[formGroupName].controls;
  }
  removeParametersControls(parameters: QueryParameter2[]) {
    parameters.forEach((x) => {
      if (x.Type != QueryParameterType.MultipleOption)
        this.queryForm.removeControl(x.Name);
    });
  }

  addParametersControls(parameters: QueryParameter2[]) {
    console.log(parameters);
    parameters.forEach((x) => {
      if (x.Type != QueryParameterType.MultipleOption)
        this.queryForm.addControl(
          x.Name,
          new UntypedFormControl(
            x.Type === 'D'
              ? this.datePipe.transform(new Date(), 'yyyy-MM-dd')
              : '',
            x.Required === 'Y' ? Validators.required : null
          )
        );
    });
  }

  onClickCancel() {
    this.modalService.dismissAll();
  }

  ValidateParamsTypeMulti(): boolean {
    return this.parameters
      .filter((x) => x.Type === QueryParameterType.MultipleOption)
      .some(
        (x) =>
          x.ValidValues.filter((i) => i.IsSelected).length == 0 &&
          x.Required == 'Y'
      );
  }

  onClickAccept() {
    let x = this.ValidateParamsTypeMulti();
    if (this.queryForm.invalid || this.ValidateParamsTypeMulti()) {
      this.alertService.Alert('Algunos campos son requeridos', 'info', 'modal');
      return;
    }

    let result: OpenCounting = {
      IdCounting: 0,
      IdQuery: this.queryForm.get('QueryId').value,
      Parameters: this.getParamsToUseInQuery(this.parameters),
    };
    this.modalService.dismissAll(result);
  }

  shareCheckedList(item: any[]) {
    // console.log(item);
  }
  shareIndividualCheckedList(item: {
    idParam: number;
    value: string;
    checked: boolean;
  }) {
    console.log('EMITER', item);

    console.log(this.parameters);

    this.parameters
      .find((x) => x.Id == item.idParam)
      .ValidValues.find((y) => y.Option == item.value).IsSelected =
      item.checked;
  }

  getParamsToUseInQuery(queryParameters: QueryParameter2[]): Parameter[] {
    let paramsToUse: Parameter[] = [];
    queryParameters.forEach((queryParam) => {
      let controlValue: any;
      if (queryParam.Type != QueryParameterType.MultipleOption)
        controlValue = this.queryForm.get(queryParam.Name).value;

      let paramToUse: Parameter;
      switch (queryParam.Type) {
        case QueryParameterType.Alpha:
          paramToUse = new Parameter(
            queryParam.Name,
            QueryParameterType.Alpha,
            controlValue
          );
          break;
        case QueryParameterType.MultipleOption:
          let valuesToUse = queryParam.ValidValues.filter((x) => x.IsSelected);
          paramToUse = new Parameter(
            queryParam.Name,
            QueryParameterType.MultipleOption,
            valuesToUse.map((x) => x.Value).join(';')
          );
          break;
        case QueryParameterType.Boolean:
          paramToUse = new Parameter(
            queryParam.Name,
            QueryParameterType.Boolean,
            controlValue
          );
          break;
        case QueryParameterType.Date:
          paramToUse = new Parameter(
            queryParam.Name,
            QueryParameterType.Date,
            controlValue
          );
          break;
        case QueryParameterType.Numeric:
          paramToUse = new Parameter(
            queryParam.Name,
            QueryParameterType.Numeric,
            controlValue
          );
          break;
      }

      paramsToUse.push(paramToUse);
    });

    return paramsToUse;
  }

  getQueries() {
    this.queryService
      .GetQueries()
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result) this.queries = response.Data;
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  onChangeQuery() {
    this.removeParametersControls(this.parameters);
    this.parameters = [];

    const query = this.queries.find(
      (query) => query.Id === +this.queryForm.get('QueryId').value
    );

    if (!query) return;
    if (query.ObjectType === QueryObjectType.View) return;

    this.queryService
      .GetQueryParameters(query.Id)
      .pipe(first())
      .subscribe(
        (response) => {
          //this.alertService.HandleApiResponse(response);
          if (response.Result) {
            this.parameters = response.Data;
            this.addParametersControls(this.parameters);
          }
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  OnClickAcordeonRow($event){

  }

}
