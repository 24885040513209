import { environment } from "src/environments/environment";

export const Menu: Option[] = [
  {
    Name: 'Inicio',
    Path: 'home',
    Icon: 'fa-home',
    Active: true,
    Open: false,
    Children: null,
    Key: null,
  },
  {
    Name: 'Configuración',
    Path: null,
    Icon: 'fa-cogs',
    Active: false,
    Open: false,
    Children: [
      {
        Name: 'Usuarios',
        Path: 'users',
        Icon: 'fa-users',
        Active: false,
        Open: false,
        Children: null,
        Key: 2,
      },
      {
        Name: 'Permisos',
        Path: 'permission-rols',
        Icon: 'fa-key',
        Active: false,
        Open: false,
        Children: null,
        Key: 3,
      },
    ],
    Key: null,
  },
  {
    Name: 'Conteos',
    Path: null,
    Icon: 'fa-calculator',
    Active: false,
    Open: false,
    Children: [
      {
        Name: 'Conteos',
        Path: 'inv-countings',
        Icon: 'fa-clipboard-list',
        Active: false,
        Open: false,
        Children: null,
        Key: 4,
      },
      {
        Name: 'Revision',
        Path: 'revision',
        Icon: 'fa-clipboard-check',
        Active: false,
        Open: false,
        Children: null,
        Key: 12,
      },
      {
        Name: 'Queries',
        Path: 'queries',
        Icon: 'fa-font',
        Active: false,
        Open: false,
        Children: null,
        Key: 5,
      },
      {
        Name: 'Asignación de contadores',
        Path: 'assignation/0',
        Icon: 'fa-exchange-alt',
        Active: false,
        Open: false,
        Children: null,
        Key: 6,
      },
      {
        Name: 'Reportes',
        Path: 'reports',
        Icon: 'fa-file-pdf',
        Active: false,
        Open: false,
        Children: null,
        Key: 13,
      },
    ],
    Key: 666,
  },
  {
    Name: 'Salir',
    Path: null,
    Icon: 'fa-sign-out-alt',
    Active: false,
    Open: false,
    Children: null,
    Key: null,
  },
];

export const Pages: Page[] = [
  {
    Page: 'users',
    Key: 2,
  },
  {
    Page: 'new-user',
    Key: 2,
  },
  {
    Page: 'permission-rols',
    Key: 3,
  },
  {
    Page: 'queries',
    Key: 5,
  },
  {
    Page: 'new-query',
    Key: 5,
  },
  {
    Page: 'inv-countings',
    Key: 4,
  },
  {
    Page: 'inv-counting',
    Key: 4,
  },
  {
    Page: 'assignation',
    Key: 6,
  },
  {
    Page: 'revision',
    Key: 12,
  },
  {
    Page: 'reports',
    Key: 13,
  },
  {
    Page: 'report',
    Key: 13,
  },
];

export interface Option {
  Name: string;
  Path?: string;
  Icon: string;
  Active: boolean;
  Open: boolean;
  Children?: Option[];
  Key?: number;
}

export interface Page {
  Page: string;
  Key: number;
}

export class AppConstants {
  static get ApiUrl(): string {
    // const URLS = {
    //   DEV: 'https://zbinventapidev.clavisco.com/',
    //   TEST: 'https://zbinventapitest.clavisco.com/',
    //   PROD: 'https://zbinventapi.clavisco.com/',
    //   LOCAL: 'http://localhost:55666/',
    return environment.ApiUrl;
  }

  static get RPTMANAGER_URL(): string {
    //TEST
    return 'https://rptmanagertest.clavisco.com/';
    //PROD
    //return 'https://rptmanager.clavisco.com/';
  }
  static getMenu() {
    return Menu;
  }

  public static GetError(_error: any): string {
    const JOINED_ERROR =
      _error?.Error?.Message ||
      _error?.error?.Error?.Message ||
      _error?.error ||
      _error;

    if (typeof JOINED_ERROR === 'string') return JOINED_ERROR;

    return JSON.stringify(_error);
  }
}
