


export class User {
  Id: number;
  Email: string;
  Password: string;
  Active: boolean | string;
  VerificationCode: string;
}
