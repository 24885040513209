import { QueryParameter2 } from "./query-parameter";

export class Query {
  Id: number;
  Name: string;
  ObjectType: string;
  Description: string;
  CreateDate: Date;
  Active: string;
  UserMappId: number;
}

export interface Query2 extends Query {
  Parameters: QueryParameter2;
}
