<!-- <nav id="header" class="row fixed-top py-2 border-bottom">
  <div class="col-md-1 col-4 d-flex align-items-center justify-content-center">
    <button class="btn no-shadow" (click)="sidebarService.toggle()">
      <i
        class="fas fa-bars fa-lg"
        [ngClass]="{
          'fa-bars': !sidebarService.sidebarOpened,
          'fa-chevron-left': sidebarService.sidebarOpened
        }"
      ></i>
    </button>
  </div>
  <div class="col-md-5 col-8 d-flex align-items-center">
    <h4></h4>
  </div>
  <div class="col-md-6 col-12 text-truncate d-none d-md-inline-block">
    <div class="row">
      <div class="col-11 d-flex flex-row-reverse text-right">
        <button class="btn btn-outline-danger no-shadow" (click)="logOut()">
          <i class="fas fa-sign-out-alt fa-lg"></i>
        </button>
        <div class="mx-3">
          <h6>Zebol S.A</h6>
          <h6>{{ sessionService.Session.User }}</h6>
        </div>
      </div>
    </div>
  </div>
</nav> -->


<header class="row  sticky-top m-0 shadow-sm">
  <div class="col-md-6">
      <div class="row pl-3">
          <button type="button" class="btn mx-1 my-1" (click)="sidebarService.toggle()"><i class="fa fa-bars fa-lg"
                  aria-hidden="true"></i></button>
          <div class="pt-1">
              <h5 class="mt-2">{{currentPath}}</h5>
          </div>
      </div>
  </div>
  <div class="col-md-6 text-right pt-1">
      <i class="fa fa-2x fa-user-circle-o mt-2" style="cursor: pointer;" aria-hidden="true" [title]="currentUser"></i>
  </div>
</header>