import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AssignationComponent } from './components/inv-counting/assignation/assignation.component';
import { InvCountingsComponent } from './components/inv-counting/inv-countings/inv-countings.component';
import { NewCountingComponent } from './components/inv-counting/new-counting/new-counting.component';
import { RevisionComponent } from './components/inv-counting/revision/revision.component';
import { LoginComponent } from './components/login/login.component';
import { PermissionRolsComponent } from './components/premission/permission-rols/permission-rols.component';
import { PrintReportComponent } from './components/print-report/print-report.component';
import { NewQueryComponent } from './components/query/new-query/new-query.component';
import { QueriesComponent } from './components/query/queries/queries.component';
import { ReportsComponent } from './components/reports/reports.component';
import { NewUserComponent } from './components/user/new-user/new-user.component';
import { UsersComponent } from './components/user/users/users.component';
import { AuthorizationGuard } from './guards';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'new-user',
    component: NewUserComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'new-user/:id',
    component: NewUserComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'permission-rols',
    component: PermissionRolsComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'queries',
    component: QueriesComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'revision',
    component: RevisionComponent,
     canActivate: [AuthorizationGuard],
  },
  {
    path: 'new-query',
    component: NewQueryComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'new-query/:id',
    component: NewQueryComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'inv-countings',
    component: InvCountingsComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'inv-counting/:invCountingId',
    component: NewCountingComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'assignation/:invCountingId',
    component: AssignationComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'report/:reportId',
    component: PrintReportComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
