import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppConstants, Menu } from './common';
import { SectionNameService, SessionService, SidebarService } from './services';
import {environment} from "../environments/environment";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  menu: any;
  sidebarMode: string;
  title = 'Zebol inventarios'
  ApiUrl = environment.ApiUrl;
  constructor(
    private router: Router,
    public sidebarService: SidebarService,
    public sessionService: SessionService,
    private sectionNameService: SectionNameService
  ) {
    this.menu = AppConstants.getMenu();
    this.sidebarMode = 'push';
  }

  ngOnInit() {
    this.handleSessionValidity();
  }

  onClickMenuOption(option: any) {

    if (option.Name == 'Salir') {
      this.logOut();
      return;
    }


    this.inactivate(this.menu);
    option.Active = true;
    if (option.Children) option.Open = !option.Open;
    this.navigate(option);
  }

  onClickSubMenuOption(option: any, sub: any) {
    this.inactivate(this.menu);
    option.Active = true;
    sub.Active = true;
    this.navigate(sub);
  }

  inactivate(options: any[]) {
    options.forEach((x) => {
      x.Active = false;
      if (x.Children) this.inactivate(x.Children);
    });
  }

  navigate(option: any) {
    if (!option.Path) return;

    this.router.navigateByUrl(option.Path);
  }

  logOut() {
    this.sessionService.DisableApp();
  }

  handleSessionValidity() {
    if (this.sessionService.ValidSessionStored())
      this.sessionService.EnableApp();
    else this.sessionService.DisableApp();
  }
}
