import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, Rol } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RolsService {

  private URL = 'api/Rols';

  constructor(private http: HttpClient) {}

  GetRols() {
    return this.http.get<ApiResponse<Rol[]>>(`${this.URL}/GetRols`, {});
  }

  GetRol(id: number) {
    return this.http.get<ApiResponse<Rol>>(`${this.URL}/GetRol?id=${id}`, {});
  }

  PostRol(rol: Rol) {
    return this.http.post<ApiResponse<Rol>>(
      `${this.URL}/PostRol`,
      rol,
      {}
    );
  }

  PutRol(rol: Rol) {
    return this.http.put<ApiResponse<Rol>>(
      `${this.URL}/PutRol`,
      rol,
      {}
    );
  }

  StateRol(rol: Rol) {
    return this.http.put<ApiResponse<Rol>>(
      `${this.URL}/StateRol`,
      rol,
      {}
    );
  }
}
