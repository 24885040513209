<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabelll"><i class="fas fa-1x fa-info-circle "></i>
    {{tittle}} </h5>
  <button type="button" class="close " style="outline:none;" aria-label="Close" (click)="OnclickAccep()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-2">
  <h4></h4>
  <div class="container">
    <div class="tableFixHead mt-2">
      <table class="table table-striped mt table-sm table-hover">

        <thead style="background-color: rgb(128,188,91); color: white;">
          <tr>
            <th scope="col" style="width: 5%;">#</th>
            <th scope="col" style="width: 25%;">Nombre</th>
            <!-- <th scope="col" style="width: 25%;">Por definir</th>
            <th scope="col" style="width: 25%;">Por definir</th> -->

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of userlist; let i = index" class="p-0">
            <td scope="row">{{(i + 1)}}</td>
            <td>{{ user.User.Email }}</td>
            <!-- <td>Null</td>
            <td>Null</td> -->
          </tr>
        </tbody>
      </table>
    </div>

  </div>

</div>
<div class="modal-footer">
  <div class="col">
    <button (click)="OnclickAccep()" type="button" class="btn  btn-sm"><i class="fas fa-check"></i>
      Aceptar
    </button>
  </div>
</div>