import { QueryParameterValidValue } from './query-parameter-valid-value';

export class QueryParameter {
  Id: number;
  Type: string;
  Name: string;
  Required: string;
  QueryId: number;
}

export class QueryParameter2 extends QueryParameter {
  ValidValues: QueryParameterValidValue[];
  IsRemoved:boolean;
}
