import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class InvAssignationsService {
  
  constructor(private http: HttpClient) {}

  PostAssignation(users: number[], invCoutingId: number) {
    return this.http.post<ApiResponse<boolean>>(
      `api/InvAssignations/PostAssignation?invCoutingId=${invCoutingId}`,
      users,
      {}
    );
  }


  RemoveUserAsiggnation(Id: number) {
    return this.http.delete<ApiResponse<boolean>>(
      `api/InvAssignations/RemoveUserAsiggnation?userID=${Id}`,
    );
  }
}
