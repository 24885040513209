import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map } from 'rxjs/operators';
import { Alerts } from 'src/app/common';
import { Query, QueryParameter2 } from 'src/app/models';
import { AlertService, BlockUIService, QueriesService } from 'src/app/services';

@Component({
  selector: 'app-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.scss'],
})
export class QueriesComponent implements OnInit {
  queries: Query[];
  queriesNames: string[];
  copuQueries: Query[];
  selectedQuery: Query;
  queryParams: QueryParameter2[];
  constructor(
    private blockUI: BlockUIService,
    private modalService: NgbModal,
    private router: Router,
    private queryService: QueriesService,
    private alertService: AlertService
  ) {
    this.queries = [];
  }

  ngOnInit(): void {
    this.getQueries();
  }



  searchQuery = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? this.getQueries()
        : this.queriesNames.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  selectedQueri(item: any) {
    if (item) {
      let idQuey = this.copuQueries.find(q => q.Name === item.item);
      if (idQuey) {
        this.queryService.GetQuery(idQuey.Id).subscribe(data => {
          if (data.Result) {
            this.queries = [];
            this.queries.push(data.Data);
          }
        });
      }
    }

  }

  onClickFindQueries() {
    (<HTMLInputElement>document.getElementById('searchInput')).value = '';
    this.getQueries();
  }

  RiseQueryParams(content: any, id: number) {

    this.selectedQuery = this.queries.find(q => q.Id == id);
    if (this.selectedQuery) {
      this.queryService.GetQueryParameters(id).subscribe(data => {
        if (data.Result) {
          this.queryParams = data.Data;
          if (this.queryParams.length > 0) {
            let modal = this.modalService.open(content,
              {
                centered: true,
                backdrop: 'static'
              });
          } else {
            this.alertService.Alert('El query no cuenta con parametros', Alerts.info, 'toast');
          }
        } else {
          this.alertService.Alert('No se pudo obtener los parametros del query', Alerts.error, 'toast');
        }
      });
    }
  }

  getQueries() {

    this.blockUI.start('Obteniendo queries, por favor espere...')
    this.queryService
      .GetQueries()
      .pipe(first())
      .subscribe(
        (response) => {
          //this.alertService.HandleApiResponse(response);
          if (response.Result) {
            this.queries = response.Data;
            this.queriesNames = this.queries.map(x => x.Name);
            this.copuQueries = [...this.queries];
          }
          this.blockUI.stop();
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.HandleUnknownError(err);
        }
      );
  }


  onClickNewQuery() {
    this.router.navigateByUrl('new-query');
  }

  onClickEditQuery(qry: Query) {
    this.router.navigateByUrl('new-query/' + qry.Id);
  }

  async onClickDeleteQuery(queryID: Query) {

    let confirmationResult = await this.alertService.Confirmation(
      'Confirmación',
      '¿Desea eliminar el Query?',
      'Eliminar'
    );

    if (!confirmationResult) return;


    this.blockUI.start('Eliminando query, por favor espere...');

    this.queryService.RemoveQuery(queryID.Id).subscribe(response => {
      this.blockUI.stop();
      if (response.Result) {
        this.alertService.Alert('Query eliminado correctamente..', Alerts.success, 'modal');
        this.getQueries();
      } else {
        this.alertService.Alert('No se pudo eliminar el query, motivo: ' + response.ErrorInfo.Message, Alerts.warning, 'modal');
      }
    }, err => {
      this.alertService.Alert('Error eliminando el query, motivo: ' + err, Alerts.error, 'modal');
      this.blockUI.stop();
    });


  }
}
