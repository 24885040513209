import { Parameter } from '../other/parameter';

export class Helper{
  IsSelected:boolean;
}



export class InventoryCounting extends Helper {
  Id: number;
  Name: string;
  Description: string;
  Comments: string;
  Status: number;
  CreateDate: Date;
  CompleteDate?: Date;
  Deadline: Date;
  QueryId: number;
  UserMappId: number;
  WhCode:string;
  ClosingComment:string;
  IsAssigned:boolean;
  UserEmail:string;
}

export class InventoryCounting2 extends InventoryCounting {
  ParametersForQuery?: Parameter[];
}
