<block-ui>
  <div class="container-fluid" id="parent">
    <div class="row">
      <form class="col-md-4 offset-md-4 col-8 offset-2" [formGroup]="loginForm">
        <div class="container shadow border rounded pt-2 pb-4">
          <div class="row">
            <div class="col-12 d-flex justify-content-start align-items-center mt-3">
              <img src="../../../assets/images/Zebol-logo.png" alt="Zebol" />
            </div>
            <div class="col-12 mb-3">
              <div class="form-group">
                <h5 class="text-muted">Iniciar sesión</h5>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fas fa-at"></i>
                  </span>
                </div>
                <input type="text" class="form-control" placeholder="Email" formControlName="Email" [ngClass]="{
                    'is-invalid':
                      formControls.Email.errors && formControls.Email.touched
                  }" />
                <div *ngIf="formControls.Email.errors" class="invalid-feedback">
                  <small *ngIf="formControls.Email.errors.required">
                    Este campo es requerido
                  </small>
                  <small *ngIf="formControls.Email.errors.pattern">
                    Correo inválido
                  </small>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i (click)="ShowPAssword()" style="cursor: pointer;" class="fas fa-unlock-alt"></i>
                  </span>
                </div>


               
                  <input id="user_password" type="password" class="form-control" placeholder="Contraseña"
                    formControlName="Password" [ngClass]="{
                    'is-invalid':
                      formControls.Password.errors &&
                      formControls.Password.touched
                  }" />
                  

                <div *ngIf="formControls.Password.errors" class="invalid-feedback">
                  <small *ngIf="formControls.Password.errors.required">
                    Este campo es requerido
                  </small>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-end">
              <button class="btn btn-success px-5" (click)="onClickLogin()">
                <span *ngIf="loginForm.disabled" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
                <span *ngIf="loginForm.enabled">Ingresar</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</block-ui>