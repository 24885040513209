<div class="d-flex flex-row-reverse py-2">
    <div class="d-flex flex-row-reverse">
        <div class="form-group mb-0">
          <button class="form-control form-control-sm btn-light shadow-sm btn-st" (click)="onNewRol(newRolModalContainer, null)">
            <i class="fa fa-plus" aria-hidden="true"></i>
            Agregar Rol
          </button>
        </div>
      </div>
    <!-- <button class="btn btn-primary btn-sm" (click)="onNewRol(newRolModalContainer, null)">
        Crear Rol
    </button> -->
</div>
<div class="row py-2">
    <div class="list-group col-md-6">
        <div class="list-group-item bg-white">
            <h5 class="m-0">Roles</h5>
        </div>
        <button type="button" *ngFor="let rol of rols"
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            [ngClass]="(rolSelected && (rolSelected.Id == rol.Id))?'selectedItem':''" (click)="onSelectRol(rol)">
            {{ rol.Name }} ({{ rol.Active ? 'Activo':'Inactivo'}})
            <!--
            <button class="btn btn-outline-secondary bg-white btn-sm"
                (click)="onNewRol(newRolModalContainer, rol);$event.stopPropagation()">
                <i class="fas fa-edit text-secondary"></i>
            </button>
            -->
        </button>
    </div>
    <div class="list-group col-md-6">
        <div class="list-group-item bg-white">
            <div class="form-group form-check mb-0">
                <input #permisionCheck type="checkbox" class="form-check-input" id="all-permissions"
                    (click)="changeSelectList(permisionCheck.checked)">
                <label class="form-check-label h5 m-0" for="all-permissions">Permisos</label>
            </div>
        </div>

        <div style="overflow-y: scroll; height:50vh;">



            <button type="button" *ngFor="let permission of permissions"
                class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                (click)="onSelectPermission(permission)">
                <div class="form-group form-check mb-0">
                    <input type="checkbox" class="form-check-input"
                        (click)="onCheckPermission(permission);$event.stopPropagation()"
                        [checked]="permission.isSelected" [disabled]="!permission.permission.Active">
                    <label class="form-check-label">{{ permission.permission.Name }} ({{ permission.permission.Active ?
                        'Activo':'Inactivo'}})</label>
                </div>
                <button class="btn btn-outline-secondary bg-white btn-sm"
                    (click)="onNewPermission(newPermissionModalContainer, permission.permission);$event.stopPropagation()">
                    <i class="fas fa-edit text-secondary"></i>
                </button>
            </button>

        </div>





    </div>
</div>

<ng-template #newPermissionModalContainer>
    <app-new-permission [permission]="sendPermission"></app-new-permission>
</ng-template>

<ng-template #newRolModalContainer>
    <app-new-rol [rol]="sendRol"></app-new-rol>
</ng-template>