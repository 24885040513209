import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { YesNo } from 'src/app/common';
import { Company, Rol, User } from 'src/app/models';
import { AlertService, BlockUIService, UsersService } from 'src/app/services';
import { CompaniesService } from 'src/app/services/companies.service';
import { RolsService } from 'src/app/services/rols.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent implements OnInit {
  userForm: UntypedFormGroup;
  userMappingForm: UntypedFormGroup;

  rols: Rol[];
  companies: Company[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private userService: UsersService,
    private blockUI: BlockUIService,
    private alertService: AlertService,
    private rolsService: RolsService,
    private companiesService: CompaniesService
  ) {
    this.userForm = this.formBuilder.group({
      Id: [-1],
      Email: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$'),
        ],
      ],
      Password: [''],
      Active: [YesNo.Yes, Validators.required],
      VerificationCode: [null],
    });
    this.userMappingForm = this.formBuilder.group({
      Id: [-1],
      SlpCode: ['', Validators.required],
      SlpName: ['', Validators.required],
      SAPUser: ['', Validators.required],
      SAPPassword: ['', Validators.required],
      WarehouseCode: ['', Validators.required],
      UserId: [-1, Validators.required],
      CompanyId: ['', Validators.required],
      RolId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.rols = [];
    this.companies = [];
    this.loadRols();
    this.loadCompanies();
    let id = this.route.snapshot.paramMap.get('id');
   
    if (id) {
      this.loadUser(parseInt(id));
    }

    
  }

  loadRols(){
    this.rolsService
      .GetRols()
      .pipe(first())
      .subscribe(
        (response) => {
          this.rols = response.Data;
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      )
  }

  loadCompanies(){
    this.companiesService
      .GetCompanies()
      .pipe(first())
      .subscribe(
        (response) => {
          this.companies = response.Data;
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      )
  }

  loadUser(id: number){
    this.userService
      .GetUser(id)
      .pipe(first())
      .subscribe(
        (response) => {
          response.Data.userData.Active = response.Data.userData.Active
            ? YesNo.Yes
            : YesNo.No;
          this.userForm.setValue(response.Data.userData);
          this.userMappingForm.setValue(response.Data.userMappingData);
        
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      );
}

  get userFormControls() {
    return this.userForm.controls;
  }

  get userMappingFormControls() {
    return this.userMappingForm.controls;
  }


  onClickDescartar():void{
    this.router.navigateByUrl('users');
    
  }



  onClickSaveChanges() {
    if (this.userForm.invalid || this.userMappingForm.invalid) return;

    let user: User = {
      ...this.userForm.value,
    };
    let userMapping = {
      ...this.userMappingForm.value,
    };

    // TODO simplificar la evaluacion
    user.Active = user.Active == YesNo.Yes ? true : false;

    this.blockUI.start('Guardando Usuario...');
    if (user.Id > 0) {
      this.userService
        .PutUser(user, userMapping)
        .pipe(first())
        .subscribe(
          (response) => {
            this.blockUI.stop();
            this.alertService.HandleApiResponse(response);
            this.router.navigateByUrl('users');
          
          },
          (err) => {
            this.blockUI.stop();
            this.alertService.HandleUnknownError(err);
          }
        );
    } else {
      this.userService
        .PostUser(user, userMapping)
        .pipe(first())
        .subscribe(
          (response) => {
            this.blockUI.stop();
            this.alertService.HandleApiResponse(response);
            if(response.Result) this.router.navigateByUrl('users');
          },
          (err) => {
            this.blockUI.stop();
            this.alertService.HandleUnknownError(err);
          }
        );
    }
  }
}
