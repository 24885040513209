export enum QueryParameterType {
  Alpha = 'A',
  Numeric = 'N',
  Boolean = 'B',
  Date = 'D',
  MultipleOption = 'M',
}

export enum QueryObjectType {
  View = 'V',
  Procedure = 'P',
}

export enum YesNo {
  Yes = 'Y',
  No = 'N',
}

export enum Alerts {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export enum InvCountingState {
  NotStarted = 1,
  Open = 2,
  Closed = 3,
}

export enum REPORT_PARAMETER {
  Alpha = 1,
  Numeric,
  Date,
  Boolean,
  MultipleOption,
}

// export enum countStatus {
//   Created = 1, // Creado
//   Opened = 2, // Abierto
//   Counted = 3, // Contado
//   Revised = 4, // Revisado
//   Closed = 5, // Cerrado
//   Expired = 6, // Expirado
//   AdmRevision = 7 // Revision administrador
// }


export enum countStatus {
  Created = 1, // Creado
  Opened = 2, // Abierto
  Closed = 3, // Cerrado
  Expired = 4, // Expirado
  AdmRevision = 5, // Revision administrador
  Accepted = 6
}




export enum CountingMovsType {
  Descarga = 1,
  Conteo = 2,
  Revision = 3,
  AdmRevision =4
}
