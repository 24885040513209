import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { Permission, Rol } from 'src/app/models';
import { AlertService } from 'src/app/services';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RolsService } from 'src/app/services/rols.service';

class PermissionCheck {
  permission: Permission;
  isSelected: boolean;
}

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  rols: Rol[];
  permissions: PermissionCheck[];

  rolSelected: Rol;

  sendRol: Rol;
  sendPermission: Permission;

  constructor(
    private rolsService: RolsService,
    private permissionsServices: PermissionsService,
    private alertService: AlertService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.rols = [];
    this.permissions = []
    this.sendRol = null;
    this.sendPermission = null;
    this.loadData()
  }

  loadData(){
    this.getRols();
    this.getPermissions();
  }

  getRols(){
    this.rolsService
      .GetRols()
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result) this.rols = response.Data;
          if (this.rols.length > 0) this.onSelectRol(this.rols[0])
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  getPermissions(){
    this.permissionsServices
      .GetPermissions()
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result){
            this.permissions = [];
            response.Data.forEach(x => {
              this.permissions.push({permission: x, isSelected: false})
            })
          }
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  selectPermisionRols(rol: Rol){
    if(rol){
    this.permissionsServices
      .GetPermissionIdsByRol(rol.Id)
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result){
            this.permissions.forEach(x => x.isSelected = false)
            response.Data.forEach(x => {
              let permission = this.permissions.find(y => y.permission.Id == x)
              if(permission) permission.isSelected = !permission.isSelected
            });
          }
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      );
    }
  }

  changeSelectList(check: boolean){
      this.permissions.forEach(x => {if(x.permission.Active) x.isSelected = check})
      this.updateRolPermissions()
  }

  onNewRol(modalReference: any, rol: Rol){
    this.sendRol = rol;

    let modal = this.modalService.open(modalReference, {
      size: 'lg',
      backdrop: false,
      scrollable: true,
      backdropClass: 'modal-backdrop',
    });

    modal.result.then(
      () => {},
      (result: Rol) => {
        if (result) {
          if(result.Id === -1){
            this.rolsService.PostRol(result).subscribe(res => {
              if(res.Result) {
                this.getRols()
                this.onSelectRol(this.rolSelected)
              }
            }, err =>{
              this.alertService.HandleUnknownError(err);
            });
          } else {
            this.rolsService.PutRol(result).subscribe(res => {
              if(res.Result) {
                this.getRols();
                this.onSelectRol(this.rolSelected);
              }
            }, err =>{
              this.alertService.HandleUnknownError(err);
            });
          }
        }
      }
    );
  }

  onNewPermission(modalReference: any, permission: Permission){
    this.sendPermission = permission;

    let modal = this.modalService.open(modalReference, {
      size: 'lg',
      backdrop: false,
      scrollable: true,
      backdropClass: 'modal-backdrop',
    });

    modal.result.then(
      () => {},
      (result: Permission) => {
        if (result) {
          if(result.Id === -1){
            this.permissionsServices.PostPermission(result).subscribe(res => {
              if(res.Result) {
                this.getPermissions(); 
                this.onSelectRol(this.rolSelected)
              }
            }, err =>{
              this.alertService.HandleUnknownError(err);
            });
          } else {
            this.permissionsServices.PutPermission(result).subscribe(res => {
              if(res.Result) {
                this.getPermissions(); 
                this.onSelectRol(this.rolSelected)
              }
            }, err =>{
              this.alertService.HandleUnknownError(err);
            });
          }
        }
      }
    );
  }

  onSelectRol(rol: Rol){
    if(rol){
      if(rol.Active){
      this.rolsService
        .GetRol(rol.Id)
        .pipe(first())
        .subscribe(
          (response) => {
            if (response.Result) this.rolSelected = response.Data
            this.selectPermisionRols(this.rolSelected);
          },
          (err) => {
            this.alertService.HandleUnknownError(err);
          }
        );
      } else {
        this.alertService.Alert('El rol está inactivo, no se puede asignar', 'error', 'toast')
      }
    }
  }

  onSelectPermission(permission: PermissionCheck){
    if(permission.permission.Active){
      if(permission) permission.isSelected = !permission.isSelected
      this.updateRolPermissions()
    } else {
      if(permission) permission.isSelected = false
      this.alertService.Alert('El permiso está inactivo, no se puede asignar', 'error', 'toast')
    }
  }

  onCheckPermission(permission: PermissionCheck){
    this.onSelectPermission(permission);
    // El evento se repite y se ejecuta solo en onSelectPermission,
    // el área se mantiene para futuras funcionalidades que no
    // dependan del onSelectPermission
    //permission.isSelected = !permission.isSelected
  }

  updateRolPermissions(){
    let permissionsIds: number[] = [];
    this.permissions.forEach(x => {
      if(x.isSelected){
        permissionsIds.push(x.permission.Id)
      }
    })
    this.permissionsServices.PostPermissionRols(permissionsIds, this.rolSelected.Id)
    .pipe(first())
    .subscribe(
      (response) => {
        if(response.Result) this.alertService.Alert('Permisos de rol actualizados', 'info', 'toast')
      },
      (err) => {
        this.alertService.HandleUnknownError(err);
      }
    );
  }

}
