<block-ui>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row py-2">
          <div class="col-4 col-md-2">
            <h4 class="text-truncate" title="Todos los conteos">

            </h4>
          </div>
          <div class="col-8 col-md-6">
            <input (selectItem)="SearchCounting($event)" [ngbTypeahead]="searchCounting" id="searchInput" type="search"
              class="form-control form-control-sm" placeholder="Nombre o descripción..." />
          </div>
          <div class="col-12 col-md-4">


            <button class="btn btn-sm btn-light" (click)="onClickSearch()">
              <i class="fa fa-search" aria-hidden="true"></i>
              Buscar

            </button>

            <div *ngIf="!hiddenOptions?.New" class="d-flex flex-row-reverse">
              <div class="form-group mb-0">
                <button class="form-control form-control-sm btn-light shadow-sm btn-st" routerLink="/inv-counting/0">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  Agregar conteo
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-12">

        <table class="table table-striped table-sm table-responsive-sm table-bordered table-hover">
          <thead style="background-color: rgb(128,188,91); color: white;">
            <tr>
              <th scope="col" style="width: 3%;">#</th>
              <th scope="col" style="width: 3%;">Código</th>
              <th scope="col" style="width: 17%;">Nombre</th>
              <th scope="col" style="width: 22%;">Descripción</th>
              <th scope="col">Usuario</th>
              <th scope="col" style="width: 17%;">Creado</th>
              <th scope="col" style="width: 17%;">Fecha límite</th>
              <th scope="col" style="width: 15%;">Días restantes</th>
              <th scope="col" style="width: 4%;">Estado</th>
              <th scope="col" style="width: 3%;">Opc</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let invCounting of invCountings; index as i" class="p-0">
              <th scope="row">{{(i + 1)}}</th>
              <th scope="row">{{invCounting.Id}}</th>
              <td (click)="ShowCountDetails(invCounting)"
                class="form-group input-group-sm ml-auto mr-auto ">
                <strong class="RiseParams">{{invCounting.Name}} </strong>
              </td>
              <td> {{invCounting.Description | slice:0:26 }} <i title="Click para ver descripción completa"
                  (click)="OpenDescriptionModal(ModalDescription, invCounting)"
                  *ngIf="invCounting.Description.length>15 " role="button" class="fa fa-info"></i></td>
              <td>{{invCounting.UserEmail}}</td>
              <td>{{invCounting.CreateDate | date: "short"}}</td>
              <td>{{invCounting.Deadline | date: "short"}}</td>
              <td style="text-align: center;">{{ invCounting.Deadline | daysDiff }}</td>

              <td> {{invCounting.Status|statusType}} <i class="CloseCountClass fa fa-info" *ngIf="invCounting.Status==3"
                  (click)="ShowCloseReason(ModalCloseComment,invCounting)"></i>
              </td>

              <td>
                <div *ngIf="invCounting.Status === 1">
                  <button container="body" type="button" (click)="SetCurrendCount(invCounting)" class="btn mt-1 mb-auto"
                    style="background-color:#ecf0f8; color:black; border: none; padding: 0px;" placement="left"
                    [ngbPopover]="popContent" [popoverTitle]="popTitle">
                    <i class="fa fa-cogs mt-1"></i>
                  </button>
                </div>

                <div *ngIf="invCounting.Status >1 && invCounting.Status !=6">
                  <button container="body" type="button" (click)="SetCurrendCount(invCounting)" class="btn mt-1 mb-auto"
                    style="background-color:#ecf0f8; color:black; border: none; padding: 0px;" placement="left"
                    [ngbPopover]="popOpenCounting" [popoverTitle]="popTitle">
                    <i class="fa fa-cogs mt-1"></i>
                  </button>
                </div>


              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ng-template #popTitle>Opciones</ng-template>
    <ng-template #popContent>
      <button *ngIf="currendCount.IsAssigned" class="btn btn-sm btn-light btn-block text-left"
        (click)="openModalQueryParams(QueryParams)"><i class="fa fa-unlock" style="cursor: pointer;"
          title="Abrir conteo"></i> Abrir conteo </button>

      <button *ngIf="currendCount.IsAssigned" class="btn btn-sm btn-light btn-block text-left"
        (click)="openModalUsersAssigned(UsersAssigned)"><i class="fa fa-info" style="cursor: pointer;"
          title="Asignaciones"></i> Asignaciones </button>

      <button class="btn btn-sm btn-light btn-block text-left" (click)="OnclickAssigCount()"><i class="fa fa-list"
          style="cursor: pointer;" title="Asignar conteo"></i>
        Asignar conteo</button>

      <button class="btn btn-sm btn-light btn-block text-left" (click)="OnclickEditCount()"><i class="fa fa-edit"
          style="cursor: pointer;" title="Editar conteo"></i>
        Editar</button>

      <!-- <button class="btn btn-sm btn-light btn-block text-left" (click)="onClickDeleteInvCounting(i)"><i
          class="fa fa-trash" style="cursor: pointer;" title="Eliminar conteo"></i> Eliminar
        conteo</button> -->
    </ng-template>

    <ng-template #popOpenCounting>
      <button *ngIf="currendCount.IsAssigned" class="btn btn-sm btn-light btn-block text-left"
        (click)="openModalUsersAssigned(UsersAssigned)"><i class="fa fa-info" style="cursor: pointer;"
          title="Asignaciones"></i> Asignaciones </button>
      <button class="btn btn-sm btn-light btn-block text-left" (click)="OnclickOpenModalCloseCount(ModalCloseCount)"><i
          class="fa fa-lock" style="cursor: pointer;" title="Cerrar conteo."></i>
        Cerrar conteo</button>
    </ng-template>
  </div>

  <!-- #Modales -->

  <!--Usuarios asignados a un conteo-->
  <ng-template #UsersAssigned>
    <app-assigned-users [Id]="currendCount.Id"></app-assigned-users>
  </ng-template>

  <!--Query cuando se abre un conteo-->
  <ng-template #QueryParams>
    <app-qry-params></app-qry-params>
  </ng-template>

  <!--Cerrar conteo-->
  <ng-template #ModalCloseCount let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabelll"><i class="fas fa-1x fa-info-circle "></i>
        Cierre del conteo {{currendCount.Name}} </h5>
      <button type="button" class="close " style="outline:none;" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <h4>Razon de cierre</h4>
      <div class="container">
        <div class=" mt-2">
          <textarea [(ngModel)]="comentarioCierre" name="comment" id="" cols="30" rows="10"></textarea>

        </div>

      </div>

    </div>
    <div class="modal-footer">
      <div class="col">
        <button (click)="modal.dismiss('Cross click')" type="button" class="btn  btn-sm" data-dismiss="modal"><i
            class="fas fa-ban"></i>
          Cancelar
        </button>

        <button (click)="CloseCount()" class="btn  btn-sm"><i class="fas fa-check"></i>Aceptar</button>
      </div>
    </div>

  </ng-template>

  <!--Modal comentario de cierre-->
  <ng-template #ModalCloseComment let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabelll"><i class="fas fa-1x fa-info-circle "></i>
        {{currendCount.Name}} </h5>
      <button type="button" class="close " style="outline:none;" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <h5>Razon de cierre</h5>
      <div class="container">
        <div class=" mt-2">
          <p>{{currendCount.ClosingComment}}</p>

        </div>

      </div>

    </div>
    <div class="modal-footer">
      <div class="col">
        <button (click)="modal.dismiss('Cross click')" type="button" class="btn  btn-sm" data-dismiss="modal"><i
            class="fas fa-check"></i>
          Aceptar
        </button>
      </div>
    </div>

  </ng-template>

  <ng-template #ModalDescription let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabelll"><i class="fas fa-1x fa-info-circle "></i>
        {{currendCount.Name}} </h5>
      <button type="button" class="close " style="outline:none;" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <div class="container">
        <div class=" mt-2">
          <p>{{currendCount.Description}}</p>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <div class="col">
        <button (click)="modal.dismiss('Cross click')" type="button" class="btn  btn-sm" data-dismiss="modal"><i
            class="fas fa-check"></i>
          Aceptar
        </button>
      </div>
    </div>
  </ng-template>

  <!-- # Fin modales -->
</block-ui>