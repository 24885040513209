export class Parameter {
  Value: any;
  Type: string;
  Name: string;
  UseNull: boolean;

  constructor(name: string, type: string, value: any = null) {
    this.Name = name;
    this.Type = type;
    
    if (value) {
      this.Value = value;
      this.UseNull = false;
    } else {
      this.UseNull = true;
    }
  }
}
