import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alerts } from 'src/app/common';
import { AlertService, BlockUIService, InvCountingsService } from 'src/app/services';

@Component({
  selector: 'app-assigned-users',
  templateUrl: './assigned-users.component.html',
  styleUrls: ['./assigned-users.component.scss']
})
export class AssignedUsersComponent implements OnInit {

  @Input('Id') Id: any;
  userlist: any[];
  tittle: string = 'Usuarios asignados al conteo.'
  constructor(private modalService: NgbModal, private alertService: AlertService,
    private blockUI: BlockUIService, private countingService: InvCountingsService) { }

  ngOnInit(): void {
    this.userlist = [];
    this.GetUsers();
  }

  OnclickAccep() {
    this.modalService.dismissAll();
  }


  GetUsers() {
    this.blockUI.start('Obteniendo usuarios.');
    this.countingService.getAssignedUsers(this.Id).subscribe(result => {
      this.blockUI.stop();
      if (result.Result) {
        this.userlist = result.Data;
        if (!this.userlist || this.userlist.length == 0) this.tittle = 'Sin usuarios asignados.';

      } else {
        this.alertService.Alert('Error obteniendo los usuarios, ' + result.ErrorInfo.Message, Alerts.info, 'toast');
      }

    }, err => {
      this.blockUI.stop();
      this.alertService.Alert('Error obteniendo los usuarios, ' + err, Alerts.info, 'toast');
    });


  }




}
