<block-ui>
  <div class="container-fluid">
    <div class="row py-2 border-bottom">
      <div class="col-4 col-md-2">
        <h4 class="text-truncate" title="Usuarios"></h4>
      </div>
  
      <div class="col-8 col-md-6">
        <input id="searchInput" (selectItem)="findUser($event)" [ngbTypeahead]="searchUser" type="search"
          class="form-control form-control-sm" placeholder="Busqueda por correo." />
      </div>
      <div class="col-12 col-md-4">
        
          <button class="btn btn-sm btn-light" (click)="onClickFindUser('')">
            <i class="fa fa-search" aria-hidden="true"></i>
            Buscar
            
          </button>
        
        <div class="d-flex flex-row-reverse">
          <div class="form-group mb-0">
            <button class="form-control form-control-sm btn-light shadow-sm btn-st" (click)="onClickNewUser()">
              <i class="fa fa-plus" aria-hidden="true"></i>
              Agregar usuario
            </button>
          </div>
        </div>
  
      </div>
    </div>
  
  
  <!-- Añadido por alejandro - 01-->
    <div class="col-12 mt-2">
      <div class="tableFixHead mt-2">
        <table class="table table-striped mt table-sm table-hover">
          <thead style="background-color: rgb(128,188,91); color: white;">
            <tr>
              <th scope="col" >#</th>
              <th scope="col">Correo</th>
              <th scope="col" class="text-center">Activo</th>
              <th scope="col" style="width: 5%;">Opc</th>
  
  
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of users; let i = index" class="p-0">
              <th scope="row">{{(i + 1)}}</th>
  
              <td>{{user.Email}}</td>
              <td class="text-center"><i (click)="onClickDeleteUser(user)" style="cursor: pointer;"
                  title="{{ user.Active ? 'Deshabilitar' : 'Habilitar' }}"
                  class="fa fa-{{user.Active ? 'check': 'times'}}"></i></td>
              <td class="justify-content-center option clasesita">
                <i class="fa fa-edit" style="cursor: pointer;" title="Editar usuario" (click)="onClickEditUser(user)"></i>
  
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  <!--Fin 01-->
  
  
  
  
  
  
      <!-- <div class="row my-2" [ngClass]="{ 'border-bottom': i !== users.length - 1 }"
            *ngFor="let user of users; index as i">
            <div class="col-8">
              <h6>{{ user.Email }}</h6>
              <p>
                {{ user.Active ? "Usuario activo" : "Usuario inactivo" }}
              </p>
            </div>
            <div class="col-4 text-center">
              <div>
                <button type="button" class="btn btn-sm btn-primary mx-1" (click)="onClickEditUser(user)">
                  Editar
                </button>
                <button type="button" class="btn btn-sm btn-primary" (click)="onClickDeleteUser(user)">
                  {{ user.Active ? "Deshabilitar" : "Habilitar" }}
                </button>
              </div>
            </div>
          </div> -->
  
  
  
    </div>
  
  
  </div>

</block-ui>

