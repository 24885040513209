<block-ui>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="row py-2">

                    <div style="display: none;" class="col-8 col-md-6">
                        <input [ngbTypeahead]="searchCounting" id="searchInput" type="search"
                            class="form-control form-control-sm" placeholder="Nombre" />
                    </div>
                    <div class="col-4 col-md-6 ">

                        <button class="btn btn-sm btn-light" (click)="onClickSearch()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                            Obtener revisiones
                        </button>
                    </div>
                </div>

            </div>
        </div>

        <div>
            <div id="table_conteiner">
                <table class="table table-striped table-sm table-responsive-sm table-bordered table-hover">
                    <thead style="background-color: rgb(128,188,91); color: white;">
                        <tr>
                            <th>#</th>
                            <th>Codigo</th>
                            <th>Nombre conteo</th>
                            <th>Usuario</th>
                            <th>Almacen</th>
                            <th>Creado</th>
                            <th>Fecha limite</th>
                            <!-- <th>Opc</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of revisionList; index as i">

                            <td>{{1+i}}</td>
                            <td>{{item.IdCount + '-' + item.ForRevisionNumber}}</td>
                            <td (click)="ShowDetails(ModalDetails,item,ModelPrograsoUsuarios)"
                                class="form-group input-group-sm ml-auto mr-auto ">
                                <strong class="RiseParams">{{item.CountName}} </strong>
                                <font color="warning" *ngIf="item.ForRevisionNumber>0">Verificación</font>
                            </td>
                            <td>{{item.UserEmail}}</td>
                            <td>{{item.WhCode}}</td>
                            <td>{{item.CreateDate | date:'short'}}</td>
                            <td>{{item.DeadLine | date:'short'}}</td>
                            <!-- <td>
                                <div>
                                    <button container="body" type="button" class="btn mt-1 mb-auto"
                                        style="background-color:#ecf0f8; color:black; border: none; padding: 0px;"
                                        placement="left" [ngbPopover]="popContent" [popoverTitle]="popTitle">
                                        <i class="fa fa-cogs mt-1"></i>
                                    </button>
                                </div>
                            </td> -->

                        </tr>
                    </tbody>
                </table>

            </div>

        </div>






        <ng-template #popOpcionesRevision>




        </ng-template>


        <!--PopOver que muestra las series con diferencia en las ubicaciones de items que se manejan con series-->
        <ng-template #popSerialNumbsContent>
            <lu>
                <li *ngFor="let item of ItemSerialNumber">
                    Serie: {{item.DistNumber}} - Diferencia: {{item.Difference}}
                </li>
            </lu>
        </ng-template>

        <ng-template #ModalDetailsd let-modal>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabelll"><i class="fas fa-1x fa-info-circle "></i>
                    Detalle de items </h5>
                <button type="button" class="close " style="outline:none;" data-dismiss="modal" aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2">
                <div id="table_conteiner">
                    <table class="table  table-sm table-responsive-sm table-bordered ">
                        <thead style="background-color: rgb(128,188,91); color: white;">
                            <tr>
                                <th>#</th>
                                <th>Codigo</th>
                                <th>Nombre</th>
                                <th>Almacen</th>
                                <th>Seleccionar</th>
                                <!-- <th style="width: 15%;">Ubicacion</th> -->
                                <!-- <th>Contado</th>
                                <th>Stock</th>
                                <th style="width: 5%;">Diferencia</th> -->

                                <!-- <th>Opc</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let detalle of movDetail; index as i">

                                <td
                                    [ngClass]="{'unCounted': detalle.Count==0 ,'moreThanStock': (detalle.Count>detalle.StockSAP), 'lessThanStock':(detalle.StockSAP>detalle.Count), 'newItem': (detalle.IsNewItem) }">
                                    {{1+i}}</td>
                                <td>{{detalle.ItemCode}}</td>
                                <td>{{detalle.Name}}</td>
                                <td>{{detalle.WhCode}}</td>
                                <td><input type="checkbox"></td> <!-- <td>{{detalle.Ubication}}</td> -->
                                <!-- <td>{{detalle.Count}}</td>
                                <td>{{detalle.StockSAP }}</td>
                                <td>{{detalle.Difference }}</td> -->
                                <!-- <td>
                                    <div>
                                        <button container="body" type="button" class="btn mt-1 mb-auto"
                                            style="background-color:#ecf0f8; color:black; border: none; padding: 0px;"
                                            placement="left" [ngbPopover]="popContent" [popoverTitle]="popTitle">
                                            <i class="fa fa-cogs mt-1"></i>
                                        </button>
                                    </div>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>

                </div>


            </div>
            <div class="modal-footer">
                <div class="col">
                    <button (click)="modal.dismiss('Cross click')" class="btn  btn-sm"><i
                            class="fas fa-check"></i>Aceptar</button>

                </div>
            </div>

        </ng-template>



        <ng-template #modal_comment let-modal>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabelll"><i class="fas fa-1x fa-info-circle "></i>
                Tittle </h5>
              <button type="button" class="close " style="outline:none;" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-2">
              <div class="container">
                <div class=" mt-2">
                  <input id="commentInputID" type="text"  placeholder="Comentario de ajuste">
                </div>
              </div>
        
            </div>
            <div class="modal-footer">
              <div class="col">
                <button (click)="modal.dismiss('Cross click')" type="button" class="btn  btn-sm" data-dismiss="modal"><i
                    class="fas fa-check"></i>
                  Aceptar
                </button>
              </div>
            </div>
          </ng-template>




        <ng-template #ModelPrograsoUsuarios let-modal>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabelll"><i class="fas fa-1x fa-info-circle "></i>
                    Progreso operarios </h5>
                <button type="button" class="close " style="outline:none;" data-dismiss="modal" aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-2">
                <div id="table_conteiner">
                    <table class="table  table-sm table-responsive-sm table-bordered ">
                        <thead style="background-color: rgb(128,188,91); color: white;">
                            <tr>
                                <th>#</th>
                                <th>Usuario</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let usuario of currentRevision.Assignations; index as i">
                                <td>{{1+i}}</td>
                                <td>{{usuario.UserEmail}}</td>
                                <td>{{usuario.Status| userProgress}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col">
                    <button (click)="modal.dismiss('Cross click')" class="btn  btn-sm"><i
                            class="fas fa-check"></i>Aceptar</button>
                </div>
            </div>
        </ng-template>

        <ng-template #ModalDetails let-modal>
            <div class="card-header" style="background-color: transparent;">
                <h6 class="float-left" style="padding: .05rem .05rem;">Revision conteo administrador <font
                        color="warning" *ngIf="!isValidForAdjustmetn">N/A</font>
                </h6>
                <button type="button" class="btn btn-sm btn-st float-right" aria-label="Close" (click)="CloseModal()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </button>



                <!-- <label class="switch" >
                        <input  type="checkbox" id="togBtn"  (click)="ToggleExpandItems()">
                        <div class="slider round">
                            <span class="off">Ocultar</span>
                            <span class="on">Desplegar</span>
                            
                        </div>
                    </label>

                    <label class="switch" >
                        <input  type="checkbox" id="togBtn" (click)="ToggleSelectAllItems()">
                        <div class="slider round">
                            
                            <span class="on">Desmarcar</span>
                            <span class="off">Marcar</span>
                            
                        </div>
                    </label>
                    
                    <label class="switch" >
                        <input  type="checkbox" id="togBtn" (click)="ToggleChangeMode()">
                        <div class="slider round">
                            
                            <span class="on">Revision</span>
                            <span class="off">Ajuste</span>
                            
                        </div>
                    </label>  -->













                <button *ngIf="isValidForAdjustmetn" class="btn btn-sm btn-st float-right mr-2"
                    (click)="ToggleChangeMode()" title="{{Mode?'Cambiar a modo ajuste':'Cambiar a modo admin revs'}}"
                    style="background-color: transparent;">
                    <i *ngIf="!Mode" class="fa fa-toggle-off" aria-hidden="true"></i>
                    <i *ngIf="Mode" class="fa fa-toggle-on" aria-hidden="true"></i>
                </button>

                <button *ngIf="isValidForAdminRevision || isValidForAdjustmetn"
                    class="btn btn-sm btn-st float-right mr-2" (click)="ToggleSelectAllItems()"
                    title="Seleccionar todos los items" style="background-color: transparent;">
                    <i *ngIf="!selectAll" class="fa fa-toggle-off" aria-hidden="true"></i>
                    <i *ngIf="selectAll" class="fa fa-toggle-on" aria-hidden="true"></i>
                </button>

                <button class="btn btn-sm btn-st float-right mr-2" (click)="ToggleExpandItems()"
                    title="Ocultar / Desplejar todos los items" style="background-color: transparent;">
                    <i *ngIf="isExpanded" class="fa fa-toggle-off" aria-hidden="true"></i>
                    <i *ngIf="!isExpanded" class="fa fa-toggle-on" aria-hidden="true"></i>
                </button>


                <button *ngIf="Mode && isValidForAdminRevision" type="button"
                    class="btn form-control-sm float-right btn-st mr-2" (click)="SelectUsers()"><i class="fa fa-save"
                        aria-hidden="true"></i>&nbsp;
                </button>

                <app-multi-select-dropdown *ngIf="Mode && isValidForAdminRevision" class="float-right btn-st mr-2 "
                    [list]="users" (shareCheckedList)="shareCheckedList($event)"
                    (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
                </app-multi-select-dropdown>

            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card mt-1" *ngFor="let item of movDetail">

                            <div class="card-header" style="padding: 0.4rem  0.4rem; background-color: transparent;"
                                class="shadow-sm">
                                <h6 [ngClass]="{'newItem': item.IsNewItem}" (click)="ToggleViewItems(item)"
                                    style="cursor: pointer;" class="float-left">
                                    {{item.ItemCode}} - {{item.Name}}</h6>
                                <input [attr.id]="item.ItemCode" type="checkbox" class="chk big-checkbox"
                                    [checked]="item.IsSelected" (change)="CheckBoxChange(item)">
                            </div>

                            <!--Nivel de las ubicaciones-->
                            <div class="card-body"
                                [ngClass]="{'bodyCollpaser': item.IsHidden, 'bodyExpander':!item.IsHidden }">
                                <div class="row">
                                    <div class="col-sm-12">

                                        <div *ngIf="item.HasUbications" class="card mt-1">

                                            <div class="card-header"
                                                style="padding: 0.4rem  0.4rem; background-color: transparent;"
                                                class="shadow-sm">
                                                <h6 (click)="ToggleViewUbications(item,ubicacion)"
                                                    style="cursor: pointer;" class="float-left">
                                                    Almacen: {{item.WhCode}} -
                                                    Stock total del almacen: {{item.Stock}}</h6>
                                            </div>

                                            <div class="card-body">
                                                <div class="row">
                                                    <div>
                                                        <div>
                                                            <table
                                                                class="tbl_01 table-striped table-sm table-responsive-sm table-bordered table-hover">
                                                                <thead>
                                                                    <tr style="color: white;">
                                                                        <th scope="col" style="width: 20%;">#</th>
                                                                        <th scope="col" style="width: 40%;">Usuario</th>
                                                                        <th scope="col" style="width: 20%;">Contado</th>
                                                                        <th scope="col" style="width: 20%;">Diferencia
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        *ngFor="let user of item.countByUser; index as i">
                                                                        <td
                                                                            [ngClass]="{'stockDif': user.Differense!=0}">
                                                                            {{i + 1}}</td>
                                                                        <td>{{user.User}} <font color="warning"
                                                                                *ngIf="user.Process>0">Verificación
                                                                                #{{user.Process}}</font>
                                                                        </td>
                                                                        <td>{{user.Counted}}</td>
                                                                        <td>{{user.Differense}}</td>

                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="card mt-1" *ngFor="let ubicacion of item.Ubications">
                                            <div class="card-header"
                                                style="padding: 0.4rem  0.4rem; background-color: transparent;"
                                                class="shadow-sm">
                                                <h6 (click)="ToggleViewUbications(item,ubicacion)"
                                                    style="cursor: pointer;" class="float-left">
                                                    Ubicacion: {{ubicacion.BinLocation}} -
                                                    Stock de la ubicación: {{ubicacion.StockSAP}}
                                                </h6>

                                            </div>
                                            <div class="card-body"
                                                [ngClass]="{'bodyCollpaser': !ubicacion.IsHidden, 'bodyExpander':ubicacion.IsHidden }">
                                                <div class="row">
                                                    <div>
                                                        <div>
                                                            <table
                                                                class="tbl_01 table-striped table-sm table-responsive-sm table-bordered table-hover">
                                                                <thead>
                                                                    <tr style="color: white;">
                                                                        <th scope="col" style="width: 20%;">#</th>
                                                                        <th scope="col" style="width: 40%;">Usuario</th>
                                                                        <th scope="col" style="width: 20%;">Contado</th>
                                                                        <th scope="col" style="width: 20%;">Diferencia
                                                                        </th>
                                                                        <!-- <th scope="col" style="width: 20%;">Seleccionado</th> -->

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        *ngFor="let user of ubicacion.countByUser; index as i">
                                                                        <td
                                                                            [ngClass]="{'stockDif': user.Differense!=0}">
                                                                            <i *ngIf="item.ManagedBySerialNumbers && user.SerialNumbers.length>0"
                                                                                [ngbPopover]="popSerialNumbsContent"
                                                                                placement="right"
                                                                                class="fa fa-info-circle" role="button"
                                                                                (click)="ClickShowSerial(user)">
                                                                            </i> {{i+ 1}}

                                                                        </td>
                                                                        <td>{{user.User}} <font color="warning"
                                                                                *ngIf="user.Process>0">Verificación
                                                                                #{{user.Process}}</font>
                                                                        </td>
                                                                        <td>{{user.Counted}}</td>
                                                                        <td>{{user.Differense}}</td>
                                                                        <!-- <td><input type="checkbox" class="chk big-checkbox" [checked]="user.SelectedDataForAdjustment"
                                                                        (change)="CheckBoxUserChange(ubicacion)"></td> -->

                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row" style="width: 100%;">
                    <div class="col-sm-2">
                        <button type="button" class="btn btn-light btn-sm shadow btn-st btn-block"
                            (click)="CloseModal()"><i class="fa fa-times-circle" aria-hidden="true"></i>
                            Cerrar&nbsp;</button>
                    </div>


                    <div class="col-sm-3">
                        <button type="button" class="btn btn-light btn-sm shadow btn-st btn-block"
                            (click)="OnClickFinalizarConteo()"><i class="fa fa-times-circle" aria-hidden="true"></i>
                            Finalizar conteo&nbsp;</button>
                    </div>

                    <div class="col-sm-">
                        <button *ngIf="!Mode" type="button" class="btn btn-light btn-sm shadow btn-st btn-block"
                            (click)="OnclickInventoryAdjustment()"><i class="fa fa-check-circle" aria-hidden="true"></i>
                            Realizar ajuste de inventario &nbsp;</button>
                    </div>
                    <div class="col-sm-3">
                        <input *ngIf="!Mode" type="text" placeholder="Comentario de ajuste"
                            [(ngModel)]="AdjustmentComment">
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</block-ui>