import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Alerts } from 'src/app/common';
import { AlertService, AuthService, BlockUIService, SessionService } from 'src/app/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private sessionService: SessionService,
    private alertService: AlertService,
    private blockUI: BlockUIService
  ) {
    this.loginForm = this.formBuilder.group({
      Email: [
        '',
        [
          Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$'),
          Validators.required,
        ],
      ],
      Password: ['', Validators.required],
    });
  }

  ngOnInit(): void { }

  get formControls() {
    return this.loginForm.controls;
  }



  ShowPAssword() {
    let input = (<HTMLInputElement>document.getElementById('user_password'));

    if (input.type === "password") {
      input.type = 'text'

    } else {
      input.type = 'password'

    }


  }


  onClickLogin() {
    if (this.loginForm.invalid || this.loginForm.disabled) return;

    this.loginForm.disable();

   // this.blockUI.start('Verificando datos, por favor espere.')
    this.authService
      .Login(
        this.loginForm.get('Email').value,
        this.loginForm.get('Password').value
      )
      .pipe(first())
      .subscribe(
        (response) => {
         // this.blockUI.stop();
          if (response.access_token) {
            response.User = this.loginForm.get('Email').value;
            this.sessionService.EnableApp(response);
            this.alertService.Alert('Bienvenido', Alerts.success, 'toast');
          } else {
            
            this.loginForm.enable();
            this.alertService.Alert(
              `Algo ha salido mal. ${response}`,
              Alerts.error,
              'modal'
            );
          }
        },
        (err) => {
         // this.blockUI.stop();
          this.loginForm.enable();
          this.alertService.Alert(err.error.error_description, Alerts.error, 'modal');
        }
      );
  }
}
