import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map } from 'rxjs/operators';
import { Alerts } from 'src/app/common';
import { User } from 'src/app/models';
import { AlertService, BlockUIService, UsersService } from 'src/app/services';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  users: User[];
  userEmails:string[];
  copyUsers:User[];
  constructor(
    private blockUI:BlockUIService,
    private router: Router,
    private usersService: UsersService,
    private alertService: AlertService
  ) {
    this.users = [];
  }




  ngOnInit(): void {
    this.getUsers();
  }

  searchUser = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? this.getUsers()
        : this.userEmails.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  getUsers() {
    this.blockUI.start('Obteniendo usuarios, por favor espere..');
    this.usersService
      .GetUsers()
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result){
            this.users = response.Data;
            this.userEmails = this.users.map(u=>u.Email);
            this.copyUsers = [...this.users];
          } 
          this.blockUI.stop();
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  onClickNewUser() {
    this.router.navigateByUrl('new-user');
  }


  findUser(pParam: any) {
    if (pParam) {
      let user = this.copyUsers.find(u => u.Email === pParam.item);
      if (user) {
        this.usersService.GetUser(user.Id).subscribe(data => {
          if (data.Result) {
            this.users = [];
            this.users.push(data.Data.userData);
          }
        });

      }
    }
  }


  onClickFindUser(param: string) {
    (<HTMLInputElement>document.getElementById('searchInput')).value = '';
    this.getUsers();

  }

  onClickEditUser(user: User) {
    this.router.navigateByUrl('new-user/' + user.Id);
  }

  onClickDeleteUser(user: User) {
    user.Active = !user.Active;
    this.usersService.StateUser(user).subscribe(
      (response) => {
        //this.getUsers();
        let msj ='Usuario '.concat(user.Active?'habilitado':'inhabilitado');
      
        this.alertService.Alert(msj,Alerts.success,'toast');
      },
      (err) => {
        this.alertService.HandleUnknownError(err);
      }
    );
  }
}
