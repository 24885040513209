import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '../common';
import { Parameter } from '../models';
import { Email } from '../models/report-manager/i-email';
import { ParameterReport } from '../models/report-manager/i-parameter-report';
import { Report } from '../models/report-manager/i-report';
import { BaseResponse } from '../models/responses/api-response';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class RptManagerService {

  constructor(private http: HttpClient, private storageService: SessionService) { }


  SaveReportFile(report: File) {

    console.log(report)
    let session = this.storageService.Session;
    let AppKey: string;
    let CompanyKey: string;

    if (session) {

      AppKey = session.AppKey;
      CompanyKey = session.CompanyKey;
    }

    const formData = new FormData();
    formData.append('report', report);
   
    return this.http.post<BaseResponse>(`${AppConstants.RPTMANAGER_URL}api/Reports/SaveReportFile?companyKey=${CompanyKey}&appKey=${AppKey}`, formData);
  }


  HandlePostOrPutReport(report: Report) {

    let session = this.storageService.Session;
    let AppKey: string;
    if (session) {
      AppKey = session.AppKey;
    }

    report.ApplicationId = +AppKey; //#VALORPRUEBA //#EMA DISUMED TEST 8//EMA PROD 5   

    if (report.Id > 0) {
      return this.http.put<any>(
        `${AppConstants.RPTMANAGER_URL}api/Reports/PutReport`,
        report,
        {}
      );
    } else {
      return this.http.post<any>(
        `${AppConstants.RPTMANAGER_URL}api/Reports/PostReport`,
        report,
        {}
      );
    }
  }



  GetReports() {
    let session = this.storageService.Session;
    let AppKey: string;
    let CompanyKey: string;

    if (session) {

      AppKey = session.AppKey;
      CompanyKey = session.CompanyKey;
    }

    return this.http.get<any>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/GetReports?companyKey=${CompanyKey}&appKey=${AppKey}`,
      {}
    );
  }


  GetParameters(reportId: number) {

    return this.http.get<any>(`${AppConstants.RPTMANAGER_URL}api/Parameter/GetParameters?reportId=${reportId}`, {});
  }

  PrintReport(parameters: ParameterReport[], reportId: number) {

    return this.http.post<any>(`${AppConstants.RPTMANAGER_URL}api/Reports/PrintReport?reportId=${reportId}`, parameters, {});
  }


  SendEmail(emailInfo: Email, reportId: number) {
    return this.http.post<any>(`${AppConstants.RPTMANAGER_URL}api/Reports/SendEmail?reportId=${reportId}`, emailInfo, {});
  }


  DownloadFile(reportId: number) {

    return this.http.get<any>(`${AppConstants.RPTMANAGER_URL}api/Reports/DownloadFile?reportId=${reportId}`, {});
  }

  GetReportUsers() {

    return this.http.get<any>(`${AppConstants.RPTMANAGER_URL}api/ReportUser/GetReportUsers`, {});
  }


}
