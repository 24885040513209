<ng-sidebar-container>
  <ng-sidebar [sidebarClass]="'sidebar-class'" [(opened)]="sidebarService.sidebarOpened" [mode]="sidebarMode">
    <div id="sidebar-content" class="container-fluid">
      <div class="row">
        <div class="col-12 mb-5">
          <div class="row" style="height: 55px;">
            <div class="col-4 text-center">
              <img id="logo" class="img-fluid" src="../assets/images/logo-zebol.svg" >
            </div>
            <div class="col-8 py-3">
              <p class="m-1">ZEBOL</p>
            </div>
          </div>
        </div>
        <div class="col-12 my-1" *ngFor="let option of menu; index as i">
          <div class="row">
            <div class="col-12 p-2 menu-option rounded-pill pointer"
              [ngClass]="{'menu-option-selected': option.Active && i>0}" data-toggle="collapse"
              [attr.data-target]="'#sub-' + i" aria-expanded="false" [attr.aria-controls]="'sub-' + i"
              (click)="onClickMenuOption(option)">
              <div class="row">
                <div class="col-1">
                  <i *ngIf="option.Children" class="fas" [ngClass]="{
                      'fa-chevron-down': option.Open,
                      'fa-chevron-right': !option.Open
                    }"></i>
                </div>
                <div class="col-1">
                  <i class="fas" [ngClass]="option.Icon"></i>
                </div>
                <div class="col">
                  <h6>{{ option.Name }}</h6>
                </div>
              </div>
            </div>
            <div class="col-12 collapse" [attr.id]="'sub-' + i">
              <div class="row pointer mt-2" *ngFor="let sub of option.Children"
                (click)="onClickSubMenuOption(option, sub)" [ngClass]="{'submenu-option-selected': sub.Active}">
                <div class="col-1">&nbsp;</div>
                <div class="col-1">
                  <i class="fas" [ngClass]="sub.Icon"></i>
                </div>
                <div class="col text-truncate">
                  <h6>{{ sub.Name }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-sidebar>
  <div ng-sidebar-content>
    <app-header *ngIf="sessionService.validSession"></app-header>
    <div class="py-2" id="app-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-sidebar-container>