import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { YesNo } from 'src/app/common';
import { Permission } from 'src/app/models';
import { RolsService } from 'src/app/services/rols.service';

@Component({
  selector: 'app-new-permission',
  templateUrl: './new-permission.component.html',
  styleUrls: ['./new-permission.component.scss']
})
export class NewPermissionComponent implements OnInit {

  permissionForm: UntypedFormGroup;

  @Input()
  permission: Permission;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private rolsService: RolsService
  ) { 
    this.permissionForm = this.formBuilder.group({
      Id: [-1],
      Key: ['', Validators.required],
      Name: ['', Validators.required],
      Category: ['', Validators.required],
      Active: [YesNo.Yes, Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    if(this.permission){
      this.permissionForm.setValue(this.permission);
      this.permissionForm.controls["Active"].setValue((this.permission.Active)?YesNo.Yes:YesNo.No)
    }
  }

  onClickCancel() {
    console.log("-Modal cerrada-")
    this.modalService.dismissAll();
  }

  onClickAccept() {
    if (this.permissionForm.invalid) return;

    let permission: Permission = {
      ...this.permissionForm.value
    };
    permission.Active = (this.permissionForm.controls["Active"].value == YesNo.Yes)
    this.modalService.dismissAll(permission);
  }

  get permissionFormControls() {
    return this.permissionForm.controls;
  }
}
