import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { QueryParameterValidValue } from 'src/app/models';

@Component({
  selector: 'app-multi-select-options',
  templateUrl: './multi-select-options.component.html',
  styleUrls: ['./multi-select-options.component.scss'],
})
export class MultiSelectOptionsComponent implements OnInit {
  @Input() title: string;
  @Input() list: QueryParameterValidValue[];
  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
          ? this.list.filter(x=>!x.IsSelected).map((x) => x.Option)
          : this.list
              .filter(
                (v) => (v.Option.toLowerCase().indexOf(term.toLowerCase()) > -1 && !v.IsSelected)
              )
              .map((x) => x.Option)
        )
      )
    );
  };

  showDropDown: boolean;
  checkedList: any[];
  currentSelected: {};

  constructor() {
    this.checkedList = [];
  }

  ngOnInit(): void {
    console.log(this.list);
  }

  getSelectedValue(status: Boolean, value: String, IDParam: number) {
    if (status) {
      this.checkedList.push(value);
    } else {
      var index = this.checkedList.indexOf(value);
      this.checkedList.splice(index, 1);
    }

    this.currentSelected = { checked: status, value: value, idParam: IDParam };

    //share checked list
    this.shareCheckedlist();

    //share individual selected item
    this.shareIndividualStatus();
  }

  shareCheckedlist() {
    console.log('Listaa', this.checkedList);
    this.shareCheckedList.emit(this.checkedList);
  }

  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }

  GetSelectedList(): any[] {
    return this.list.filter((x) => x.IsSelected);
  }

  OnSelectItem(item: any, input: any): void {
    let valueSelected = this.list.find((x) => x.Option === item.item);

    if (valueSelected) {
      item.preventDefault();
      valueSelected.IsSelected = !valueSelected.IsSelected;
      input.value = '';
    }
  }


  OnclickRemoveParam(paramName:string):void{

    let paramIndex = this.list.findIndex(x=> x.Option == paramName);

    if(paramIndex != -1){
      this.list[paramIndex].IsSelected = false;
    }

  }

  AddAll():void{
    this.list.forEach(x=> x.IsSelected = true);
  }

  RemoveAll():void{
    this.list.forEach(x=> x.IsSelected = false);
  }


}
