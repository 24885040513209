<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="row py-2 border-bottom">
        <div class="col-6">
          <h4>
            {{
            ( (formControls("invCountingForm").Id.value) && (formControls("invCountingForm").Id.value > 0) )
            ? "Editar conteo"
            : "Nuevo conteo"
            }}
          </h4>
        </div>
        <div class="col-6 text-right">
          <div class="d-flex flex-row-reverse mt-4">
            <div class="form-group">
              <button class="form-control form-control-sm btn-light shadow-sm btn-st float-right"
                (click)="onClickSaveChanges()">
                <i class="fa fa-floppy-o" aria-hidden="true"></i>
                Guardar
              </button>
            </div>
            <div class="form-group">
              <button routerLink="/inv-countings"
                class=" form-control form-control-sm btn-light shadow-sm btn-st float-right mx-1">
                <i class="fa fa-ban" aria-hidden="true"></i>
                Descartar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <nav ngbNav #nav="ngbNav" class="nav-tabs mt-2">
        <ng-container ngbNavItem>
          <a ngbNavLink>Conteo</a>
          <ng-template ngbNavContent>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12" [formGroup]="invCountingForm">
                  <div class="row">


                    <div class="col-4">
                      <div class="form-group">
                        <label>Nombre <span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" formControlName="Name" [ngClass]="{
                            'is-invalid':
                              formControls('invCountingForm').Name.errors &&
                              formControls('invCountingForm').Name.touched
                          }" />
                        <div *ngIf="formControls('invCountingForm').Name.errors" class="invalid-feedback">
                          <small *ngIf="
                              formControls('invCountingForm').Name.errors
                                .required
                            ">
                            Este campo es requerido
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Fecha de cierre
                          <span class="text-danger">*</span></label>
                        <input type="date" class="form-control form-control-sm" formControlName="Deadline" [ngClass]="{
                            'is-invalid':
                              formControls('invCountingForm').Deadline.errors &&
                              formControls('invCountingForm').Deadline.touched
                          }" />
                        <div *ngIf="
                            formControls('invCountingForm').Deadline.errors
                          " class="invalid-feedback">
                          <small *ngIf="
                              formControls('invCountingForm').Deadline.errors
                                .required
                            ">
                            Este campo es requerido
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <label for="">Almacen</label>
                      <select class="form-control form-control-sm" formControlName="WhCode" [ngClass]="{
                        'is-invalid':
                          formControls('invCountingForm').WhCode.errors &&
                          formControls('invCountingForm').WhCode.touched
                      }">

                        <option [ngValue]="null" [disabled]="true">Almacenes</option>
                        <option class="form-control" *ngFor="let wh of warehouses" [ngValue]="wh.WhCode">{{wh.WhName}} -
                          {{wh.WhCode}}
                        </option>

                      </select>

                      <div *ngIf="
                            formControls('invCountingForm').WhCode.errors
                          " class="invalid-feedback">
                        <small *ngIf="
                              formControls('invCountingForm').WhCode.errors
                                .required
                            ">
                          Este campo es requerido
                        </small>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label>Descripción <span class="text-danger">*</span></label>
                        <textarea rows="5" class="form-control form-control-sm" formControlName="Description" [ngClass]="{
                            'is-invalid':
                              formControls('invCountingForm').Description
                                .errors &&
                              formControls('invCountingForm').Description
                                .touched
                          }">
                        </textarea>
                        <div *ngIf="
                            formControls('invCountingForm').Description.errors
                          " class="invalid-feedback">
                          <small *ngIf="
                              formControls('invCountingForm').Description.errors
                                .required
                            ">
                            Este campo es requerido
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>Comentarios (serán visualizados en la aplicación
                          móvil)</label>
                        <textarea rows="5" class="form-control form-control-sm" formControlName="Comments">
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>




        <!-- <ng-container ngbNavItem>
          <a ngbNavLink>Query</a>
          <ng-template ngbNavContent>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12" [formGroup]="queryForm">
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label>Query <span class="text-danger">*</span></label>
                        <select class="form-control form-control-sm" [ngClass]="{
                            'is-invalid':
                              formControls('queryForm').QueryId.errors &&
                              formControls('queryForm').QueryId.touched
                          }" formControlName="QueryId" (change)="onChangeQuery()">
                          <option *ngFor="let query of queries" [value]="query.Id">
                            {{ query.Name }}
                          </option>
                        </select>
                        <div *ngIf="formControls('queryForm').QueryId.errors" class="invalid-feedback">
                          <small *ngIf="
                              formControls('queryForm').QueryId.errors.required
                            ">
                            Este campo es requerido
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="row">
                        <div class="col-12">
                          <label>Lista de parámetros</label>
                        </div>
                        <div class="col-12" *ngIf="parameters.length === 0">
                          <label>El objeto seleccionado no posee
                            parámetros...</label>
                        </div>
                        <div class="col-12" *ngFor="let parameter of parameters" [ngSwitch]="parameter.Type">
                          <div class="form-group">
                            <label>
                              {{ parameter.Name }}
                              <span class="text-danger" *ngIf="parameter.Required">
                                *
                              </span>
                            </label>
                            <input type="text" class="form-control form-control-sm" [ngClass]="{
                                'is-invalid':
                                  formControls('queryForm')[parameter.Name]
                                    .errors &&
                                  formControls('queryForm')[parameter.Name]
                                    .touched
                              }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'A'" />
                            <input type="number" class="form-control form-control-sm" [ngClass]="{
                                'is-invalid':
                                  formControls('queryForm')[parameter.Name]
                                    .errors &&
                                  formControls('queryForm')[parameter.Name]
                                    .touched
                              }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'N'" />
                            <input type="date" class="form-control form-control-sm" [ngClass]="{
                                'is-invalid':
                                  formControls('queryForm')[parameter.Name]
                                    .errors &&
                                  formControls('queryForm')[parameter.Name]
                                    .touched
                              }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'D'" />
                            <input type="checkbox" class="form-control form-control-sm" [ngClass]="{
                                'is-invalid':
                                  formControls('queryForm')[parameter.Name]
                                    .errors &&
                                  formControls('queryForm')[parameter.Name]
                                    .touched
                              }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'B'" />
                            <select class="form-control form-control-sm" [ngClass]="{
                                'is-invalid':
                                  formControls('queryForm')[parameter.Name]
                                    .errors &&
                                  formControls('queryForm')[parameter.Name]
                                    .touched
                              }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'M'">
                              <option [value]="option.Value" *ngFor="let option of parameter.ValidValues">
                                {{ option.Option }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container> -->


      </nav>
    </div>
    <div class="col-12">
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
