import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Alerts } from 'src/app/common';
import { InventoryCounting, InventoryCounting2 } from 'src/app/models/db/inventory-counting';
import {
  AlertService,
  BlockUIService,
  InvCountingsService,
  QueriesService,
} from 'src/app/services';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { WareHouse } from 'src/app/models/other/WareHouse';
import { ApiResponse } from 'src/app/models';

@Component({
  selector: 'app-new-counting',
  templateUrl: './new-counting.component.html',
  styleUrls: ['./new-counting.component.scss'],
  providers: [DatePipe],
})
export class NewCountingComponent implements OnInit {
  invCountingForm: UntypedFormGroup;
  // queryForm: FormGroup;
  // queries: Query[];
  // parameters: QueryParameter2[];
  warehouses: WareHouse[];
  invCountingId: number;
  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private queryService: QueriesService,
    private InvCountingService: InvCountingsService,
    private blockUI: BlockUIService,
    private datePipe: DatePipe
  ) {

    

    this.invCountingId = +this.activatedRoute.snapshot.paramMap.get('invCountingId');
  }

  ngOnInit(): void {
    // this.getQueries();
    this.GetWareHouses();
    this.initApp();
  }

  initApp() {
    this.invCountingForm = this.formBuilder.group({
      Id: [null],
      Name: ['', Validators.required],
      Deadline: [this.datePipe.transform(
        new Date(),
        'yyyy-MM-dd'
      ), Validators.required],
      Description: ['', Validators.required],
      Comments: [''],
      Status: [null],
      WhCode: ['', [Validators.required]],
    });
    // this.queryForm = this.formBuilder.group({
    //   QueryId: [null, Validators.required],
    // });
    // this.queries = [];
    // this.parameters = [];
  }

  onClickSaveChanges() {
    if (this.invCountingForm.invalid) {

      this.invCountingForm.markAllAsTouched();
      return;
    }

    //Original
    // const invCounting: InventoryCounting2 = {
    //   ...this.invCountingForm.value,
    //   QueryId: +this.queryForm.get('QueryId').value,
    //   ParametersForQuery: this.getParamsToUseInQuery(this.parameters),
    // };

    const invCounting: InventoryCounting2 = {
      ...this.invCountingForm.value
    };


    this[invCounting.Id ? 'editInvCounting' : 'saveNewInvCounting'](
      invCounting
    );
  }

  // onChangeQuery() {
  //   this.removeParametersControls(this.parameters);
  //   this.parameters = [];

  //   const query = this.queries.find(
  //     (query) => query.Id === +this.queryForm.get('QueryId').value
  //   );

  //   if (!query) return;
  //   if (query.ObjectType === QueryObjectType.View) return;

  //   this.queryService
  //     .GetQueryParameters(query.Id)
  //     .pipe(first())
  //     .subscribe(
  //       (response) => {
  //         this.alertService.HandleApiResponse(response);
  //         if (response.Result) {
  //           this.parameters = response.Data;
  //           this.addParametersControls(this.parameters);
  //         }
  //       },
  //       (err) => {
  //         this.alertService.HandleUnknownError(err);
  //       }
  //     );
  // }

  // getQueries() {
  //   this.queryService
  //     .GetQueries()
  //     .pipe(first())
  //     .subscribe(
  //       (response) => {
  //         this.alertService.HandleApiResponse(response);
  //         if (response.Result) this.queries = response.Data;
  //       },
  //       (err) => {
  //         this.alertService.HandleUnknownError(err);
  //       }
  //     );
  // }

  formControls(formGroupName: string) {
    return this[formGroupName].controls;
  }

  resetPage() {
    this.invCountingForm.reset();
    // this.queryForm.reset();
    // this.removeParametersControls(this.parameters);
    // this.parameters = [];
  }

  // removeParametersControls(parameters: QueryParameter2[]) {
  //   parameters.forEach((x) => {
  //     this.queryForm.removeControl(x.Name);
  //   });
  // }

  // addParametersControls(parameters: QueryParameter2[]) {
  //   parameters.forEach((x) => {
  //     this.queryForm.addControl(
  //       x.Name,
  //       new FormControl('', x.Required ? Validators.required : null)
  //     );
  //   });
  // }


  async GetWareHouses() {
    this.InvCountingService.GetWareHouses().subscribe(data => {
      if (data.Result) {
        this.warehouses = data.Data;

        if (this.invCountingId && this.invCountingId > 0) {
          this.getInvCountingToEdit(this.invCountingId);
        }


      } else {
        this.alertService.Alert('Error al obtener los almances: ' + data.ErrorInfo.Message, Alerts.error, 'toast');
      }
    }, err => { this.alertService.Alert('Error obteniendo almacenes', Alerts.error, 'toast') });

  }




  // getParamsToUseInQuery(queryParameters: QueryParameter2[]): Parameter[] {
  //   let paramsToUse: Parameter[] = [];

  //   queryParameters.forEach((queryParam) => {
  //     let controlValue = this.queryForm.get(queryParam.Name).value;
  //     let paramToUse: Parameter;

  //     switch (queryParam.Type) {
  //       case QueryParameterType.Alpha:
  //       case QueryParameterType.MultipleOption:
  //         paramToUse = new Parameter(
  //           queryParam.Name,
  //           QueryParameterType.Alpha,
  //           controlValue
  //         );
  //         break;
  //       case QueryParameterType.Boolean:
  //         paramToUse = new Parameter(
  //           queryParam.Name,
  //           QueryParameterType.Boolean,
  //           controlValue
  //         );
  //         break;
  //       case QueryParameterType.Date:
  //         paramToUse = new Parameter(
  //           queryParam.Name,
  //           QueryParameterType.Date,
  //           controlValue
  //         );
  //         break;
  //       case QueryParameterType.Numeric:
  //         paramToUse = new Parameter(
  //           queryParam.Name,
  //           QueryParameterType.Numeric,
  //           controlValue
  //         );
  //         break;
  //     }

  //     paramsToUse.push(paramToUse);
  //   });

  //   return paramsToUse;
  // }

  async getInvCountingToEdit(invCountingId: number) {

    this.blockUI.start('Obteniendo datos.');

    this.InvCountingService.GetInvCounting(invCountingId)
      .pipe(first())
      .subscribe(
        (response : ApiResponse<InventoryCounting>) => {
          this.blockUI.stop();
          // this.alertService.HandleApiResponse(response);

          if (response.Result) {
            const invCounting = response.Data;
            this.invCountingForm.reset({
              Id: invCounting.Id,
              Name: invCounting.Name,
              Deadline: this.datePipe.transform(
                invCounting.Deadline,
                'yyyy-MM-dd'
              ),
              Description: invCounting.Description,
              Comments: invCounting.Comments,
              Status: invCounting.Status
            });

            this.invCountingForm.patchValue({ WhCode: this.warehouses?.find(x => x.WhCode === invCounting.WhCode)?.WhCode }); // Esto no funciona)
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  editInvCounting(invCounting: InventoryCounting2) {
    this.blockUI.start('Procesando...');

    this.InvCountingService.PutInvCounting(invCounting)
      .pipe(first())
      .subscribe(
        (response : ApiResponse<InventoryCounting>) => {
          this.blockUI.stop();
          this.alertService.HandleApiResponse(response);

          if (response.Result) {
            this.resetPage();
            this.router.navigateByUrl('inv-countings');
          }

        },
        (err) => {
          this.blockUI.stop();
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  saveNewInvCounting(invCounting: InventoryCounting2) {

    // if (!this.queryForm.get('QueryId').value) {
    //   this.alertService.Alert(
    //     'No has seleccionado el query para el conteo',
    //     'warning',
    //     'toast'
    //   );
    // }

    this.blockUI.start('Procesando...');

    this.InvCountingService.PostInvCounting(invCounting)
      .pipe(first())
      .subscribe(
        (response : ApiResponse<InventoryCounting>) => {
          this.blockUI.stop();
          this.alertService.HandleApiResponse(response);

          if (response.Result) {
            this.resetPage();
            this.router.navigateByUrl('inv-countings');
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.HandleUnknownError(err);
        }
      );
  }
}
