<div class="container-fluid" style="padding-left:5%;">
    <div class="row">
        <div class="col-12">
            <div class="row py-2 border-bottom">
                <div class="col-12">
                  <h4 class="text-truncate" title="Usuarios">Asignación de roles y permisos</h4>
                </div>
            </div>
            <ul class="row nav nav-tabs mt-3" id="assings" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="rols-tab" data-toggle="tab" href="#rols" role="tab"
                        aria-controls="rols" aria-selected="true" (click)="rols.loadData()">Asignación roles</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="permissions-tab" data-toggle="tab" href="#permissions" role="tab"
                        aria-controls="permissions" aria-selected="false" (click)="permissions.loadData()">Asignación permisos</a>
                </li>
            </ul>
            <div class="row tab-content mt-2" id="assings-content">
                <div class="col tab-pane fade show active" id="rols" role="tabpanel" aria-labelledby="rols-tab">
                    <app-rols #rols></app-rols>
                </div>
                <div class="col tab-pane fade" id="permissions" role="tabpanel" aria-labelledby="permissions-tab">
                    <app-permissions #permissions></app-permissions>
                </div>
            </div>
        </div>
    </div>
</div>
