export class QueryParameterValidValue {
  Id: number;
  Option: string;
  Value: string;
  Order: number;
  ParameterId: number;
  IsSelected:boolean;

  constructor(option: string, value: string) {
    this.Option = option;
    this.Value = value;
  }
}
