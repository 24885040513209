<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="row py-2 border-bottom">
        <div class="col-6">
          <h4 *ngIf="userFormControls.Id.value === -1">Nuevo usuario</h4>
          <h4 *ngIf="userFormControls.Id.value !== -1">Actualizar usuario</h4>
        </div>
        <div class="col-6 text-right">

          <div class="d-flex flex-row-reverse mt-4">

            <div class="form-group">

              <button type="button" class="form-control form-control-sm btn-light shadow-sm btn-st float-right"
                (click)="onClickSaveChanges()">
                <i class="fa fa-floppy-o" aria-hidden="true"></i>
                Guardar
              </button>
            </div>

            <div class="form-group">

              <button type="button" class="form-control form-control-sm btn-light shadow-sm btn-st float-right mx-1"
                (click)="onClickDescartar()">
                <i class="fa fa-ban" aria-hidden="true"></i>
                Descartar
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-2">
      <div class="row">
        <div class="col-md-12 border-bottom" [formGroup]="userForm">
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <label>Correo electrónico <span class="text-danger">*</span></label>
                <input type="email" class="form-control form-control-sm" formControlName="Email" [ngClass]="{
                    'is-invalid':
                      userFormControls.Email.errors &&
                      userFormControls.Email.touched
                  }" />
                <div *ngIf="userFormControls.Email.errors" class="invalid-feedback">
                  <small *ngIf="userFormControls.Email.errors.required">
                    Este campo es requerido
                  </small>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Contraseña <span class="text-danger">*</span></label>
                <input type="password" class="form-control form-control-sm" formControlName="Password" [ngClass]="{
                    'is-invalid':
                      userFormControls.Password.errors &&
                      userFormControls.Password.touched
                  }" />
                <div *ngIf="userFormControls.Password.errors" class="invalid-feedback">
                  <small *ngIf="userFormControls.Password.errors.required">
                    Este campo es requerido
                  </small>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Activo</label>
                <select class="form-control form-control-sm" formControlName="Active" [ngClass]="{
                    'is-invalid':
                      userFormControls.Active.errors &&
                      userFormControls.Active.touched
                  }">
                  <option value="Y">Si</option>
                  <option value="N">No</option>
                </select>
              </div>
            </div>
          </div>
        </div>


        <div class="col-md-12" [formGroup]="userMappingForm">
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <label>Código vendedor <span class="text-danger">*</span></label>
                <input type="text" class="form-control form-control-sm" formControlName="SlpCode" [ngClass]="{
                    'is-invalid':
                      userMappingFormControls.SlpCode.errors &&
                      userMappingFormControls.SlpCode.touched
                  }" />
                <div *ngIf="userMappingFormControls.SlpCode.errors" class="invalid-feedback">
                  <small *ngIf="userMappingFormControls.SlpCode.errors.required">
                    Este campo es requerido
                  </small>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Nombre vendedor <span class="text-danger">*</span></label>
                <input type="text" class="form-control form-control-sm" formControlName="SlpName" [ngClass]="{
                    'is-invalid':
                      userMappingFormControls.SlpName.errors &&
                      userMappingFormControls.SlpName.touched
                  }" />
                <div *ngIf="userMappingFormControls.SlpName.errors" class="invalid-feedback">
                  <small *ngIf="userMappingFormControls.SlpName.errors.required">
                    Este campo es requerido
                  </small>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label class="text-truncate">Código almacén por defecto
                  <span class="text-danger">*</span></label>
                <input type="text" class="form-control form-control-sm" formControlName="WarehouseCode" [ngClass]="{
                    'is-invalid':
                      userMappingFormControls.WarehouseCode.errors &&
                      userMappingFormControls.WarehouseCode.touched
                  }" />
                <div *ngIf="userMappingFormControls.WarehouseCode.errors" class="invalid-feedback">
                  <small *ngIf="
                      userMappingFormControls.WarehouseCode.errors.required
                    ">
                    Este campo es requerido
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <label>Usuario SAP <span class="text-danger">*</span></label>
                <input type="text" class="form-control form-control-sm" formControlName="SAPUser" [ngClass]="{
                    'is-invalid':
                      userMappingFormControls.SAPUser.errors &&
                      userMappingFormControls.SAPUser.touched
                  }" />
                <div *ngIf="userMappingFormControls.SAPUser.errors" class="invalid-feedback">
                  <small *ngIf="userMappingFormControls.SAPUser.errors.required">
                    Este campo es requerido
                  </small>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Contraseña SAP <span class="text-danger">*</span></label>
                <input type="password" class="form-control form-control-sm" formControlName="SAPPassword" [ngClass]="{
                    'is-invalid':
                      userMappingFormControls.SAPPassword.errors &&
                      userMappingFormControls.SAPPassword.touched
                  }" />
                <div *ngIf="userMappingFormControls.SAPPassword.errors" class="invalid-feedback">
                  <small *ngIf="userMappingFormControls.SAPPassword.errors.required">
                    Este campo es requerido
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <label>Compañia <span class="text-danger">*</span></label>
                <select class="form-control form-control-sm" formControlName="CompanyId" [ngClass]="{
                    'is-invalid':
                      userMappingFormControls.CompanyId.errors &&
                      userMappingFormControls.CompanyId.touched
                  }">
                  <option value="" hidden>Seleccione una compañia</option>
                  <option *ngFor="let company of companies" value="{{company.Id}}">{{company.Name}}</option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Rol <span class="text-danger">*</span></label>
                <select class="form-control form-control-sm" formControlName="RolId" [ngClass]="{
                    'is-invalid':
                      userMappingFormControls.RolId.errors &&
                      userMappingFormControls.RolId.touched
                  }">
                  <option value="" hidden>Seleccione un rol</option>
                  <option *ngFor="let rol of rols" value="{{rol.Id}}" [hidden]="!rol.Active">{{rol.Name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
