import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Models
import { ApiResponse, Query, Query2, QueryParameter2 } from '../models';

@Injectable({
  providedIn: 'root',
})
export class QueriesService {
  
  constructor(private http: HttpClient) { }

  GetParamById(paramID: number) {
    return this.http.get<ApiResponse<Query>>(`api/Queries/GetParamById?paramID=` + paramID, {});
  }

  RemoveQuery(queryID: any) {
    return this.http.delete<ApiResponse<Query>>(`api/Queries/RemoveQuery?idQuery=` + queryID, {});
  }
  GetQuery(Id: number) {
    return this.http.get<ApiResponse<Query>>(`api/Queries/GetQuerie?idQuery=` + Id, {});
  }



  RemoveValidValue(Id: number) {
    return this.http.delete<ApiResponse<Query>>(`api/Queries/RemoveValidValue?valueID=` + Id, {});
  }



  GetQueries() {
    return this.http.get<ApiResponse<Query[]>>(`api/Queries/GetQueries`, {});
  }

  PostQuery(query: Query2) {
    return this.http.post<ApiResponse<Query>>(
      `api/Queries/PostQuery`,
      query,
      {}
    );
  }

  PutQuery(query: Query2) {
    return this.http.put<ApiResponse<Query>>(`api/Queries/PutQuery`, query, {});
  }

  GetQueryParameters(queryId: number) {
    return this.http.get<ApiResponse<QueryParameter2[]>>(
      `api/Queries/GetQueryParameters?queryId=${queryId}`,
      {}
    );
  }
}
