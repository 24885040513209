import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alerts, QueryParameterType, YesNo } from 'src/app/common';
import { QueryParameter2, QueryParameterValidValue } from 'src/app/models';
import { AlertService, BlockUIService, QueriesService } from 'src/app/services';

@Component({
  selector: 'app-query-parameter',
  templateUrl: './query-parameter.component.html',
  styleUrls: ['./query-parameter.component.scss'],
})
export class QueryParameterComponent implements OnInit {
  validValues: QueryParameterValidValue[];
  parameterForm: UntypedFormGroup;
  validValueForm: UntypedFormGroup;
  tittle: string;
  @Input("paramToEdit") paramToEdit: QueryParameter2;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private blockUI: BlockUIService,
    private paramService: QueriesService,
    private alertService: AlertService
  ) {
    this.tittle = 'Nuevo parámetro';
    this.validValues = [];
    this.parameterForm = this.formBuilder.group({
      Id: [0],
      Type: [QueryParameterType.Alpha, Validators.required],
      Name: ['', Validators.required],
      Required: [YesNo.Yes],
      QueryId: [0],
    });
    this.validValueForm = this.formBuilder.group({
      Option: [{ value: '', disabled: true }, Validators.required],
      Value: [{ value: '', disabled: true }, Validators.required],
      ParameterId: [0],
    });
  }

  ngOnInit(): void {
    if (this.paramToEdit) {
      this.tittle = 'Editar parámetro'
      this.LoadParamToEdit();
    }
  }


  LoadParamToEdit(): void {
    this.parameterForm.patchValue({
      Id: this.paramToEdit.Id,
      Type: this.paramToEdit.Type,
      Name: this.paramToEdit.Name,
      Required: this.paramToEdit.Required,
      QuryId: this.paramToEdit.QueryId
    });
    // this.validValueForm.patchValue({ ...this.paramToEdit.ValidValues });
    this.validValues = this.paramToEdit.ValidValues ?? [];

    if (this.validValues.length > 0) {
      this.validValueForm.get('Option').enable();
      this.validValueForm.get('Value').enable();
    }
  }

  onClickCancel() {
    this.modalService.dismissAll();
  }

  onClickAccept() {
    if (this.parameterForm.invalid) return;

    let queryParameter: QueryParameter2 = {
      ...this.parameterForm.value,
      ValidValues: this.validValues,
    };
    this.modalService.dismissAll(queryParameter);
  }

  onClickAddValidValue() {
    let validValue = this.validValueForm.getRawValue();

    if (!validValue.Option || !validValue.Value) return;

    this.validValues.push(validValue);
    this.validValueForm.reset();
  }

  async onClickDeleteValidOption(index: number) {


    if (!this.validValues[index].ParameterId || this.validValues[index].ParameterId == 0) {
      this.validValues.splice(index, 1);
      return;
    }
    
    let confirmationResult = await this.alertService.Confirmation(
      'Confirmación',
      '¿Desea eliminar el valor?',
      'Eliminar'
    );

    if (!confirmationResult) return;





    this.blockUI.start('Eliminando..');
    this.paramService.RemoveValidValue(this.validValues[index].Id).subscribe(response => {
      this.blockUI.stop();
      if (response.Result) {
        this.alertService.Alert('Eliminado con éxito.', Alerts.info, 'toast');
        this.validValues.splice(index, 1);
      } else {
        this.alertService.Alert('Error eliminando el valor, motivo: ' + response.ErrorInfo.Message, Alerts.error, 'modal');
      }
    }, err => {
      this.alertService.Alert('Error eliminando el valor, motivo: ' + err, Alerts.error, 'modal');
    });

  }

  onChangeParameterType() {
    let type = this.parameterForm.get('Type').value;
    if (type === QueryParameterType.MultipleOption) {
      this.validValueForm.get('Option').enable();
      this.validValueForm.get('Value').enable();
    } else {
      this.validValueForm.get('Option').disable();
      this.validValueForm.get('Value').disable();
      this.validValues = [];
    }
  }

  get parameterFormControls() {
    return this.parameterForm.controls;
  }

  get optionFormControls() {
    return this.validValueForm.controls;
  }
}
