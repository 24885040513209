import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysDiff',
})
export class DaysDiffPipe implements PipeTransform {
  transform(date: string): number {
    const current = new Date();
    const timeDiff = new Date(date).getTime() - current.getTime();

    const daysDiff: any = Math.floor(timeDiff / (1000 * 3600 * 24));

    return daysDiff;
  }
}
