import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { Pages, Page } from 'src/app/common/constants';
import { AlertService, SessionService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  pages: Page[];

  constructor(
    private sessionService: SessionService,
    private alertService: AlertService
  ) {
    this.pages = Pages;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

      if(!this.sessionService.ValidSessionStored()){
        this.alertService.Alert(
          'La sesión ha expirado, inicie nuevamente.',
          'info',
          'toast'
        );
        this.sessionService.DisableApp();
        return false;
      } 



    const accessKeys = this.getAccessKeys();
    const page = this.getPageByPath(route.url[0].path);
    if (!page) return false;

    const authorized = accessKeys.some((x) => x === page.Key.toString());

    if (!authorized)
      this.alertService.Alert(
        'No tienes permiso para acceder a esta sección',
        'info',
        'toast'
      );

    return authorized;
  }
  getPageByPath(path: string) {
    return this.pages.find((x) => x.Page === path);
  }

  getAccessKeys(): string[] {
    const session = this.sessionService.Session;
    return session.AccessKeys?.split(',') || [];
  }
}
