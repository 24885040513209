import { User } from './user';




export class UserMapping {
  Id: number;
  SlpCode: number;
  SlpName: string;
  SAPUser: string;
  SAPPassword: string;
  WarehouseCode: string;
  UserId: number;
  CompanyId: number;
  RolId: number;
}

export class UserMapping2 extends UserMapping {
  User: User;
  IsSelected:boolean;
}
