import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

import { Alerts, YesNo } from 'src/app/common';
import { Query2, QueryParameter2 } from 'src/app/models';
import { AlertService, BlockUIService, QueriesService } from 'src/app/services';

@Component({
  selector: 'app-new-query',
  templateUrl: './new-query.component.html',
  styleUrls: ['./new-query.component.scss'],
})
export class NewQueryComponent implements OnInit {
  queryForm: UntypedFormGroup;
  parameters: QueryParameter2[];
  currenParam: QueryParameter2;
  tittle:string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private queryService: QueriesService,
    private blockUI: BlockUIService,
    private alertService: AlertService
  ) {
    this.queryForm = this.formBuilder.group({
      Id: [-1],
      Name: ['', Validators.required],
      ObjectType: ['', Validators.required],
      Description: ['', Validators.required],
      Active: [YesNo.Yes, Validators.required],
    });
    this.parameters = [];
    this.tittle = 'Nuevo query';
  }

  ngOnInit(): void {

    
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.tittle = 'Editar query';
      this.loadQuery(parseInt(id));
    }

  }

  onClickSaveChanges() {
    if (this.queryForm.invalid) return;

    let query: Query2 = {
      ...this.queryForm.value,
      Parameters: this.parameters,
    };

    this.blockUI.start('Procesando...');


    if (query.Id >= 0) {
      this.queryService.PutQuery(query).subscribe(data => {

        if (data.Result) {
          this.router.navigateByUrl('queries');
          this.blockUI.stop();
          this.alertService.HandleApiResponse(data);
        } else {
          this.blockUI.stop();
          this.alertService.HandleApiResponse(data);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.HandleUnknownError(error);
      });

    } else {
      this.queryService
        .PostQuery(query)
        .pipe(first())
        .subscribe(
          (response) => {
            this.blockUI.stop();
            this.alertService.HandleApiResponse(response);
            this.router.navigateByUrl('queries');
          },
          (err) => {
            this.blockUI.stop();
            this.alertService.HandleUnknownError(err);
          }
        );
    }





  }


  OnClickUpdateParameter(modalReference: any, paramID: QueryParameter2) {

    this.currenParam = paramID;
    let modal = this.modalService.open(modalReference, {
      size: 'lg',
      backdrop: false,
      scrollable: true,
      backdropClass: 'modal-backdrop',

    });

    modal.result.then(
      () => { },
      (result: any) => {
        if (result) {
          let index = this.parameters.findIndex(i => i.Id == result.Id);

          this.parameters[index] = result;

          //this.parameters.push(result);
        }
      }
    );
  }





  onClickAddParameter(modalReference: any) {
    this.currenParam = null;
    let modal = this.modalService.open(modalReference, {
      size: 'lg',
      backdrop: false,
      scrollable: true,
      backdropClass: 'modal-backdrop',
    });

    modal.result.then(
      () => { },
      (result: any) => {
        if (result) {
          this.parameters.push(result);
        }
      }
    );
  }

  async onClickDeleteParameter(index: number) {
    let confirmationResult = await this.alertService.Confirmation(
      'Confirmación',
      '¿Desea eliminar el parámetro?',
      'Eliminar'
    );

    if (!confirmationResult) return;

    //this.parameters.splice(index, 1);
    this.parameters[index].IsRemoved = true;

  }

  loadQuery(id: number): void {
    this.queryService.GetQuery(id).subscribe(data => {

      if (data.Result) {
        this.queryForm.patchValue({ Id: data.Data.Id });
        this.queryForm.patchValue({ Name: data.Data.Name });
        this.queryForm.patchValue({ Description: data.Data.Description });
        this.queryForm.patchValue({ Active: data.Data.Active });
        this.queryForm.patchValue({ ObjectType: data.Data.ObjectType });

        this.queryService.GetQueryParameters(id).subscribe(data => {

          if (data.Result) {
            this.parameters = data.Data;

          } else {
            this.alertService.Alert('Error obteniendo los parametros', Alerts.error, 'toast')
          }
        });


      }
    }, error => {

      this.alertService.HandleUnknownError(error);
    });



  }




  get formControls() {
    return this.queryForm.controls;
  }
}
