import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from './sidebar.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  validSession: boolean;

  constructor(private router: Router, private sidebarService: SidebarService) {
    this.validSession = false;
  }

  get Session() {
    let session: any = JSON.parse(localStorage.getItem('Session') || '{}');
    return session;
  }

  EnableApp(session: any = null) {
    if (session) localStorage.setItem('Session', JSON.stringify(session));
    this.router.navigateByUrl('home');
    this.sidebarService.enableSidebar();
    this.validSession = true;
  }

  DisableApp() {
    localStorage.removeItem('Session');
    this.router.navigateByUrl('login');
    this.sidebarService.disableSidebar();
    this.validSession = false;
  }

  ValidSessionStored() {
    let sessionStored = localStorage.getItem('Session');
    if (!sessionStored) return false;

    let session = JSON.parse(sessionStored);

    return new Date(session.ExpireTime) > new Date();
  }
}
