import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, Company } from '../models';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  private URL = 'api/Companies';

  constructor(private http: HttpClient) {}

  GetCompanies() {
    return this.http.get<ApiResponse<Company[]>>(`${this.URL}/GetCompanies`, {});
  }
}
