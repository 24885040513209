<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="row py-2 border-bottom">
        <div class="col-6">
          <h4>Asignación de contadores</h4>
        </div>
      </div>
    </div>
    <div class="col-8 pt-3">
      <div class="row container">
        <div class="col-12">
          <div class="form-group">
            <label>Buscar conteo</label>
            <input type="search" class="form-control form-control-sm" placeholder="Nombre del conteo"
              [ngbTypeahead]="onClickSearchUser" (focus)="focus$.next($any($event).target.value)"
              (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead"
              (selectItem)="onSelectCounting($event)" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 ">
      <div class="row container">
        <ngb-alert class="w-100" [type]="invCounting ? 'success' : 'warning' " [dismissible]="false"><strong>Conteo seleccionado: </strong> {{invCounting != null ? invCounting.Name : "Sin selecionar"}}</ngb-alert>
      </div> 
      
    </div>
    <div class="col-12">
      <div  cdkDropListGroup class="row container w-100">
        <div class="col-5 mt-2">
          <h5>Usuarios asociados</h5>
          <div
            cdkDropList
            [cdkDropListData]="assignedUsers" 
            (cdkDropListDropped)="drop($event)"
            class="example-list">
            <div class="example-box item-hover" (click)="DeleteUser(userAssigned)"  *ngFor="let userAssigned of assignedUsers"  cdkDrag>{{userAssigned.User.Email}}
          </div>
         </div>
        </div>
        <div class="col-2 mt-2">
          <h6>&nbsp;</h6>
          <button   (click)="AssingAll()"  class="btn btn-sm btn-light btn-st shadow btn-block">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </button>
          <br>
          <button (click)="DeleteAll()" class="btn btn-sm btn-light btn-st shadow btn-block">
            <i class="fa fa-arrow-left"  aria-hidden="true"></i>
          </button>
        </div>
        <div class="col-5 mt-2">
          <h5>Usuarios disponibles</h5>
      
          <div class="example-list" 
            cdkDropList
            [cdkDropListData]="users" 
            (cdkDropListDropped)="drop($event)"
          >
            <div  class="example-box item-hover" *ngFor="let user of users"  (click)="AddUser(user)"  cdkDrag>{{user.User.Email}}</div>
          </div>
      
        </div>
      </div> 
    
    </div>
   
  </div>
</div>



