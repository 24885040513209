import { Pipe, PipeTransform } from '@angular/core';
import { countStatus } from '../common/enums';

@Pipe({
  name: 'statusType'
})
export class StatusTypePipe implements PipeTransform {

  transform(value: number): unknown {
    switch (value) {
      case countStatus.Created:
        return 'Creado';
      case countStatus.Opened:
        return 'Progreso';
      case countStatus.Closed:
        return 'Cerrado';
      case countStatus.Expired:
        return 'Expirado';
      case countStatus.AdmRevision:
        return 'Revision';
        case countStatus.Accepted:
        return 'Finalizado';
      default:
        return 'Desconocido';
    }
  }

}
