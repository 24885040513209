import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppConstants } from 'src/app/common';

import {
  SectionNameService,
  SessionService,
  SidebarService,
} from 'src/app/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentUser:string;
  currentPath:string;
  menu:any[]=AppConstants.getMenu();
  constructor(
    public sidebarService: SidebarService,
    public sessionService: SessionService,
    public sectionNameService: SectionNameService,
    private router: Router
  ) {}

  ngOnInit(): void {
    
    this.currentUser = this.sessionService.Session.User;
    this.subscribeNavigationEvents();
  }

  menuMapper(target: string): void { // Mapea la ruta dirigida hacia un nombre 
    if (!target || target === 'home') this.currentPath = 'Inicio';
    

    console.log(target)
    
    this.menu.forEach(x => {
      if (x.Children?.length > 0) {
        x.Children.forEach(y => {
          if (y.Path === (target)) this.currentPath = y.Name;
          else if(y.Path != null && y.Path.split('/').length > 0 && y.Path.split('/')[0] === target ){
            this.currentPath = y.Name;
          }
        });
      }
      else {
        if (x.Path === (target)) this.currentPath = x.Name;
        else if(x.Path != null && x.Path.split('/').length > 0 && x.Path.split('/')[0] === target ){
          this.currentPath = x.Name;
        }
      }
    });
  }

  subscribeNavigationEvents() {
    
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
     
      this.menuMapper(event.url.split('/')[1])
    });
  }
 
}
