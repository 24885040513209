import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, Permission, PermissionRols } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private URL = 'api/Permissions';

  constructor(private http: HttpClient) {}

  GetPermissions() {
    return this.http.get<ApiResponse<Permission[]>>(`${this.URL}/GetPermissions`, {});
  }

  GetPermission(id: number) {
    return this.http.get<ApiResponse<Permission>>(`${this.URL}/GetPermission?id=${id}`, {});
  }

  GetPermissionIdsByRol(id: number) {
    return this.http.get<ApiResponse<number[]>>(`${this.URL}/GetPermissionIdsByRol?id=${id}`, {});
  }

  PostPermission(Permission: Permission) {
    return this.http.post<ApiResponse<Permission>>(
      `${this.URL}/PostPermission`,
      Permission,
      {}
    );
  }

  PostPermissionRols(permissionIds: number[], rolId: number) {
    let params = {
        permissionIds: permissionIds,
        rolId: rolId
    }
    return this.http.post<ApiResponse<PermissionRols>>(
      `${this.URL}/PostPermissionRols`,
      params,
      {}
    );
  }

  PutPermission(Permission: Permission) {
    return this.http.put<ApiResponse<Permission>>(
      `${this.URL}/PutPermission`,
      Permission,
      {}
    );
  }

  StatePermission(Permission: Permission) {
    return this.http.put<ApiResponse<Permission>>(
      `${this.URL}/StatePermission`,
      Permission,
      {}
    );
  }
}
