
<div>
    <div (mouseleave)="showDropDown = false">
        <button class="drop-toggle btn flat" (click)="showDropDown=!showDropDown">
            <span *ngIf="checkedList.length<=0">Usuarios</span>
            <span *ngIf="checkedList.length>0">{{checkedList.join(', ')}}</span>
            <i class="fa fa-angle-down"></i>
        </button>
        <div class="drop-show" *ngIf="showDropDown">
            <label *ngFor="let a of list">
                <input type="checkbox" [(ngModel)]="a.IsSelected" 
                    (change)="getSelectedValue(a.IsSelected,a.User.Email)" />
                    
                <span>{{a.User.Email}}</span>
            </label>
        </div>
    </div>
</div>
