import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { YesNo } from 'src/app/common';
import { Rol } from 'src/app/models';
import { RolsService } from 'src/app/services/rols.service';

@Component({
  selector: 'app-new-rol',
  templateUrl: './new-rol.component.html',
  styleUrls: ['./new-rol.component.scss']
})
export class NewRolComponent implements OnInit {

  rolForm: UntypedFormGroup;

  @Input()
  rol: Rol;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private rolsService: RolsService
  ) { 
    this.rolForm = this.formBuilder.group({
      Id: [-1],
      Name: ['', Validators.required],
      Active: [YesNo.Yes, Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    if(this.rol){
      this.rolForm.setValue(this.rol);
      this.rolForm.controls["Active"].setValue((this.rol.Active)?YesNo.Yes:YesNo.No)
    }
  }

  onClickCancel() {
    this.modalService.dismissAll();
  }

  onClickAccept() {
    if (this.rolForm.invalid) return;

    let rol: Rol = {
      ...this.rolForm.value
    };
    rol.Active = (this.rolForm.controls["Active"].value == YesNo.Yes)
    this.modalService.dismissAll(rol);
  }

  get rolFormControls() {
    return this.rolForm.controls;
  }

}
