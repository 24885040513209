<div class="container-fluid shadow">
    <div class="row border-bottom mb-2">
        <div class="col-12">
            <div class="py-4 px-1">
                <h6 *ngIf="rolFormControls.Id.value === -1">Nuevo Rol</h6>
                <h6 *ngIf="rolFormControls.Id.value !== -1">Actualizar Rol</h6>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" [formGroup]="rolForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Nombre del Rol <span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" formControlName="Name" [ngClass]="{
                    'is-invalid':
                    rolFormControls.Name.errors &&
                    rolFormControls.Name.touched
                  }" />
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <label>Activo</label>
                        <select class="form-control form-control-sm" formControlName="Active" [ngClass]="{
                    'is-invalid':
                    rolFormControls.Active.errors &&
                    rolFormControls.Active.touched
                  }">
                            <option value="Y">Si</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row border-top mt-2">
        <div class="col-12">
            <div class="py-4 px-1 d-flex justify-content-end">


                <!-- <button type="button" class="btn btn-sm btn-danger mx-1" (click)="onClickCancel()">
                    Cancelar
                </button> -->

                <div class="d-flex flex-row-reverse">
                    <div class="form-group mb-0">
                      <button class="form-control form-control-sm btn-light shadow-sm btn-st" (click)="onClickCancel()">
                        <i class="fa fa-ban" aria-hidden="true"></i>
                        Cancelar
                      </button>
                    </div>
                  </div>

                <div class="d-flex flex-row-reverse">
                    <div class="form-group mb-0">
                      <button class="form-control form-control-sm btn-light shadow-sm btn-st" (click)="onClickAccept()">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        Guardar
                      </button>
                    </div>
                  </div>
                <!-- <button type="button" class="btn btn-sm btn-success mx-1" (click)="onClickAccept()">
                    Guardar
                </button> -->
            </div>
        </div>
    </div>
</div>