import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map } from 'rxjs/operators';
import { Alerts, AppConstants } from 'src/app/common';
import { CountingMovsType, countStatus } from 'src/app/common/enums';
import { User } from 'src/app/models';
import { UserMapping2 } from 'src/app/models/db/user-mapping';
import { AdjustmentDetail, AdjustmentHeaderHelper } from 'src/app/models/other/AdjustmentHelper';
import { SeriesForAdjustment } from 'src/app/models/other/ISerialNumbers';
import { CountedByUser, IItemRevision, IItemUbication } from 'src/app/models/other/ItemRevision';
import { ItemDetailFromMov, MovDetail } from 'src/app/models/other/Mov-Detail';
import { ItemsSelected, ReopenCount } from 'src/app/models/other/ReopenCount';
import { Revision } from 'src/app/models/other/Revision';
import { AlertService, BlockUIService, InvCountingsService, SessionService, UsersService } from 'src/app/services';


@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.scss']
})
export class RevisionComponent implements OnInit {

  revisionList: Revision[];
  movDetail: IItemRevision[];
  currentRevision: Revision;
  users: UserMapping2[];
  show: boolean = true;
  isExpanded: boolean = true;
  selectAll: boolean = true;

  isValidForAdjustmetn: boolean = false;
  isValidForAdminRevision: boolean = false;
  ItemSerialNumber: SeriesForAdjustment[];


  Mode: boolean = true;

  AdjustmentComment: string;

  constructor(private CountingService: InvCountingsService,
    private blockUI: BlockUIService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private userService: UsersService,
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.users = [];
    this.revisionList = [];
    this.movDetail = [];
    this.GetRevisions();
    this.GetUsers();


  }

  searchCounting = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? this.GetRevisions()
        : this.revisionList.map(x => x.CountName + '-' + x.ForRevisionNumber).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  async OnclickInventoryAdjustment() {

    if (!this.isValidForAdjustmetn) {
      this.alertService.Alert('El conteo no es valido para ajuste de inventario ya que es anterior a una revision administrativa', Alerts.warning, 'modal');
    }

    let confirmationResult = await this.alertService.Confirmation(
      'Confirmación',
      '¿Desea realizar el ajuste de inventario?',
      'Sí'
    );
    if (!confirmationResult) return;


    if (!this.AdjustmentComment || this.AdjustmentComment.length == 0) {
      this.alertService.Alert('El comentario de ajuste es necesario', Alerts.info, 'modal');
      return;
    }

    if (this.currentRevision.Status === countStatus.AdmRevision) {

      this.blockUI.start('Verificando progreso de los operarios');

      this.CountingService.GetUsersProgressForCount(this.currentRevision.IdCount, this.currentRevision.ForRevisionNumber).subscribe(async response => {

        if (response.Result) {
          let asignaciones = response.Data;
          if (!asignaciones.every(u => u.Status == CountingMovsType.AdmRevision)) {

            // let confirmationResult = await this.alertService.Confirmation(
            //   'Aun se encuentra en proceso de revision',
            //   '¿Desea realizar el ajuste de inventario de igual manera?',
            //   'Sí'
            // );
            this.blockUI.stop();
            this.alertService.Alert('Aun se encuentra en proceso de revision por los operarios', Alerts.info, 'toast');
          } else {
            this.CreateInventoryAdjustment();
          }
        } else {
          this.alertService.Alert('Algo salio mal verificando el progreso. ', Alerts.warning, 'toast');
        }
      }, err => {
        this.blockUI.stop();
        this.alertService.Alert('Algo salio mal verificando el progreso. ' + err, Alerts.error, 'toast');
      });
    } else {
      this.blockUI.start('Realizando ajuste de inventario, espere por favor...')
      this.CreateInventoryAdjustment();
    }
  }


  CreateInventoryAdjustment() {

    if (!this.Mode) {
      // Filtramos todos los items que fueron seleccionados
      let itemToSend = this.movDetail.filter(i => i.IsSelected);
      if (itemToSend.length > 0) {
        // Crea el objeto que sera enviado al API el cual seria un header con una lista de detalles
        let adjustmentHelperToSend = this.CreateAdjustmentHelper(itemToSend);
        if (adjustmentHelperToSend) {
          this.CountingService.AdjustmentProcess(adjustmentHelperToSend).subscribe(response => {
            this.blockUI.stop();

            if (response.Result) {
              let msg: string = 'Preliminares creados con éxito,';

              let GoodIssueDocNum: number = response.Data.GoodIssueDocNum;
              let GoodReceiptDocNum: number = response.Data.GoodReceiptDocNum;


              if (GoodIssueDocNum && GoodIssueDocNum > 0) msg += ' Preliminar de salida DocNum: ' + GoodIssueDocNum;

              if (GoodReceiptDocNum && GoodReceiptDocNum > 0) msg += ' Preliminar de entrada DocNum: ' + GoodReceiptDocNum;

              this.alertService.Alert(msg, Alerts.success, 'modal');
              this.modalService.dismissAll();
              this.GetRevisions();
            } else {
              this.blockUI.stop();
              this.alertService.Alert('Algo salio mal creando el ajuste de inventario API. ' + response.ErrorInfo.Message, Alerts.error, 'toast');
            }
          }, err => {
            this.blockUI.stop();
            this.alertService.Alert('Algo salio mal creando el ajuste de inventario API. ' + err, Alerts.error, 'toast');
          });
        } else {
          this.blockUI.stop();
          this.alertService.Alert('Algo salio mal creando el ajuste de inventario UI.', Alerts.error, 'toast');
        }
      } else {
        this.blockUI.stop();
        this.alertService.Alert('Ningun item a sido seleccionado', Alerts.info, 'toast');
      }
    }

  }




  shareCheckedList(item: any[]) {
    // console.log(item);
  }
  shareIndividualCheckedList(item: {}) {
    //console.log(item);
  }



  GetRevisions(): void {
    this.blockUI.start('Obteniendo data..');

    this.CountingService.GetRevisions().subscribe(response => {
      this.blockUI.stop();
      if (response.Result) {

        this.revisionList = response.Data;
        //console.log(this.revisionList)

      } else {
        this.alertService.Alert(response.ErrorInfo.Message, Alerts.error, 'toast');

      }

    }, err => {
      this.blockUI.stop();
      this.alertService.Alert(err, Alerts.error, 'toast');
    });





  }


  OpenModalUsersProgress(modalProgreso: any) {
    const modal = this.modalService.open(modalProgreso, {
      size: 'lg',
      backdrop: false,
      scrollable: true,
      backdropClass: 'modal-backdrop',
      centered: true,
    });
  }



  ShowDetails(modalItems: any, item: Revision, modalProgreso: any): void {
    this.isExpanded = this.selectAll = false;
    this.Mode = true;
    this.AdjustmentComment = '';
    this.blockUI.start('Obteniendo data..');
    this.currentRevision = item;

    // Obtenemos el progreso de los usuarios, esto solo consulta los tipos de movimientos que han echo los usuarios asignados 
    // A un conteo y luego obtenemos los items de la revision, mostramos data si todos los usuarios ya crearon el movimiento de revision
    forkJoin({
      progres: this.CountingService.GetUsersProgressForCount(item.IdCount, this.currentRevision.ForRevisionNumber),
      items: this.CountingService.GetMovDetails(item.IdCount, this.currentRevision.ForRevisionNumber)
    }).subscribe(resultado => {
      this.blockUI.stop();
      let progresResult = resultado.progres;
      let itemsResult = resultado.items;
      if (progresResult.Result) {
        this.currentRevision.Assignations = progresResult.Data;
        if (this.currentRevision.Assignations.every(i => i.Status > CountingMovsType.Conteo)) {
          if (itemsResult.Result) {
            this.movDetail = itemsResult.Data;





            this.isValidForAdminRevision = this.isValidForAdjustmetn = (this.currentRevision.ForRevisionNumber == this.currentRevision.CountRevisionNumber);

            const modal = this.modalService.open(modalItems, {
              size: 'lg',
              backdrop: false,
              scrollable: true,
              backdropClass: 'modal-backdrop',
              centered: true,
            });
          } else {
            this.alertService.Alert('Algo ha salido mal: ' + itemsResult.ErrorInfo.Message, Alerts.error, 'toast');
          }
        } else {
          this.OpenModalUsersProgress(modalProgreso);
        }


      } else {
        this.alertService.Alert('Algo ha salido mal: ' + progresResult.ErrorInfo.Message, Alerts.error, 'toast');
      }
    }, err => {
      this.blockUI.stop();
      this.alertService.Alert('Algo ha salido mal: ' + err, Alerts.error, 'toast');
    });






    // if (!item.Assignations || item.Assignations.find(usuario => usuario.Status < 2)) {
    //   this.blockUI.stop();
    //   this.alertService.Alert('El conteo aun no ha sido procesado por los operarios', Alerts.info, 'toast');

    //   const modal = this.modalService.open(modalProgreso, {
    //     size: 'lg',
    //     backdrop: false,
    //     scrollable: true,
    //     backdropClass: 'modal-backdrop',
    //     centered: true,

    //   });
    // } else {
    //   this.CountingService.GetMovDetails(item.IdCount).subscribe(response => {
    //     this.blockUI.stop();
    //     if (response.Result) {

    //       this.movDetail = response.Data;


    //       const modal = this.modalService.open(modalItems, {
    //         size: 'lg',
    //         backdrop: false,
    //         scrollable: true,
    //         backdropClass: 'modal-backdrop',
    //         centered: true,
    //       });

    //     } else {
    //       this.alertService.Alert('Response' + response.ErrorInfo.Message, Alerts.error, 'toast');
    //     }
    //   }, err => {
    //     this.blockUI.stop();
    //     this.alertService.Alert(err, Alerts.error, 'toast');
    //   });
    // }

  }

  SelectUsers(): void {
    let usuarios = this.users.filter(i => i.IsSelected);
    let items = this.movDetail.filter(item => item.IsSelected);

    if (usuarios && usuarios.length > 0) {
      if (items && items.length > 0) {
        let nuevoConteo: ReopenCount = {
          IdCount: this.currentRevision.IdCount,
          Items: items.map(i => {
            let it: ItemsSelected = {
              ItemCode: i.ItemCode,
              ItemName: i.Name,
              ManagedBySerialNumbers: i.ManagedBySerialNumbers
            }
            return it;
          }),
          Users: usuarios.map(u => u.Id),
          UserMap: 1
        };

        this.blockUI.start('Procesando...');

        this.CountingService.CreateCountFromAdmin(nuevoConteo).subscribe(response => {
          this.blockUI.stop();
          if (response.Result) {
            this.CloseModal();
            this.GetRevisions();
            this.alertService.Alert('Conteo enviado a revision.', Alerts.success, 'toast');
          } else {
            this.alertService.Alert('A ocurrido un error ' + response.ErrorInfo.Message, Alerts.warning, 'toast');
          }
        }, err => {
          this.blockUI.stop();
          this.alertService.Alert('Error ' + err, Alerts.error, 'toast');
        });
      } else {
        this.alertService.Alert('Seleccionar al menos un item', Alerts.info, 'toast');
      }

    } else {
      this.alertService.Alert('Seleccionar al menos un usuario', Alerts.info, 'toast');
    }
  }



  ClickShowSerial(CountedBy: CountedByUser) {
    this.ItemSerialNumber = CountedBy.SerialNumbers;
  }


  CloseModal(): void {
    this.users.forEach(u => u.IsSelected = false);
    this.movDetail = [];

    this.modalService.dismissAll();
  }


  CreateAdjustmentHelper(items: IItemRevision[]): AdjustmentHeaderHelper {
    let adjustment: AdjustmentHeaderHelper = {
      Comment: this.AdjustmentComment,
      CountId: this.currentRevision.IdCount,
      details: [],
      UserMappId: 0,
      WhCode: this.currentRevision.WhCode,
      ManagedByUbications: items[0].HasUbications,
      DraftEntryDocNum: 0,
      DraftExitDocNum: 0
    };

    let details: AdjustmentDetail[] = [];

    items.forEach(item => {
      item.Ubications.forEach(ubicacion => {
        //let diferencia = (item.countByUser.map(u => u.Counted).reduce((a, b) => a + b, 0) - item.Stock);
        //Para seleccionar al primer usuario, esto en su momento debe cambiar a realizar ajustes por usuario
        ubicacion.countByUser[ubicacion.countByUser.length - 1].SelectedDataForAdjustment = true;

        if (item.ManagedBySerialNumbers && ubicacion.countByUser.find(u => u.SelectedDataForAdjustment).SerialNumbers.length > 0) {

          ubicacion.countByUser.filter(u => u.SelectedDataForAdjustment).forEach(usuario => {
            let negativas = usuario.SerialNumbers.filter(x => x.Difference < 0).map(o => o.Difference).reduce((a, b) => a + b, 0);

            let positivas = usuario.SerialNumbers.filter(x => x.Difference > 0).map(o => o.Difference).reduce((a, b) => a + b, 0);



            if (negativas != 0) {
              let ItemDiferenciaNegativa: AdjustmentDetail = {
                CountedByUser: usuario.User,
                Diferense: negativas,
                ItemCode: item.ItemCode,
                ItemName: item.Name,
                Location: ubicacion.BinLocation,
                ManagedBySerialNumbers: item.ManagedBySerialNumbers,
                BinLocationAbsEntry: ubicacion.BinLocationAbsEntry
              }
              details.push(ItemDiferenciaNegativa);
            }


            if (positivas != 0) {
              let ItemDiferenciaPostiva: AdjustmentDetail = {
                CountedByUser: usuario.User,
                Diferense: positivas,
                ItemCode: item.ItemCode,
                ItemName: item.Name,
                Location: ubicacion.BinLocation,
                ManagedBySerialNumbers: item.ManagedBySerialNumbers,
                BinLocationAbsEntry: ubicacion.BinLocationAbsEntry
              }
              details.push(ItemDiferenciaPostiva);
            }
          });
        } else {
          ubicacion.countByUser.filter(u => u.SelectedDataForAdjustment && u.Differense != 0).forEach(usuario => {
            let detail: AdjustmentDetail = {
              CountedByUser: usuario.User,
              Diferense: usuario.Differense,
              ItemCode: item.ItemCode,
              ItemName: item.Name,
              Location: ubicacion.BinLocation,
              ManagedBySerialNumbers: item.ManagedBySerialNumbers,
              BinLocationAbsEntry: ubicacion.BinLocationAbsEntry
            }
            details.push(detail);
          });

        }








      });
    });

    adjustment.details = details;
    console.table(adjustment)
    return adjustment;
  }

  CheckBoxUserChange(item: any) {

  }

  CheckBoxChange(item: IItemRevision) {

    if (!this.Mode) {

      let canBeSelect: boolean = false;

      canBeSelect = item.Ubications.some(x => x.countByUser.some(y => y.Differense != 0 || y.SerialNumbers.some(z => z.Difference != 0)));

      //canBeSelect = item.Ubications.filter(i => i.countByUser.filter(u => u.Differense != 0 || u.SerialNumbers?.filter(s => s.Difference != 0)).length > 0).length > 0;

      if (canBeSelect) {
        item.IsSelected = !item.IsSelected;
      } else {
        let checkbox = (<HTMLInputElement>document.getElementById(item.ItemCode));
        checkbox.checked = item.IsSelected = false;
        this.alertService.Alert('El item no aplica para ajuste', Alerts.info, 'toast');
      }




    } else {
      item.IsSelected = !item.IsSelected;
    }
  }


  ToggleChangeMode() {
    this.Mode = !this.Mode;
    this.selectAll = false;
    this.movDetail.forEach(x => x.IsSelected = false);

  }

  onClickSearch(): void {
    this.GetRevisions();
  }


  ToggleExpandItems(): void {

    this.movDetail.forEach(x => x.IsHidden = !this.isExpanded);
    this.isExpanded = !this.isExpanded;
  }

  ToggleSelectAllItems() {

    if (!this.Mode) {
      this.movDetail.forEach(x => {

        let canBeSelect: boolean = x.Ubications.filter(i => i.countByUser.filter(u => u.Differense != 0 || u.SerialNumbers?.filter(s => s.Difference != 0)).length > 0).length > 0;


        if (canBeSelect) {
          x.IsSelected = !this.selectAll;
        }
      });

      this.selectAll = !this.selectAll;

    } else {
      this.movDetail.forEach(x => x.IsSelected = !this.selectAll);
      this.selectAll = !this.selectAll;
    }





  }

  ToggleViewItems(item: IItemRevision): void {
    if (!this.movDetail.find(x => x.ItemCode === item.ItemCode).IsHidden) {
      this.movDetail.find(x => x.ItemCode === item.ItemCode).IsHidden = true;
    }
    else {
      this.movDetail.find(x => x.ItemCode === item.ItemCode).IsHidden = false;
    }
  }

  ToggleViewUbications(item: IItemRevision, ubication: IItemUbication): void {

    if (!item.Ubications.find(x => x.BinLocation === ubication.BinLocation).IsHidden) {
      item.Ubications.find(x => x.BinLocation === ubication.BinLocation).IsHidden = true;
    }
    else {
      item.Ubications.find(x => x.BinLocation === ubication.BinLocation).IsHidden = false;
    }
  }

  GetUsers(): void {
    this.userService.GetUserMappings().subscribe(usuarios => {

      if (usuarios.Result) {
        this.users = usuarios.Data;

      }

    });
  }




  // OnClickCreateAdjustmentOrCloseCount(modalReference: any): void {
  //   console.log(modalReference)
  //   let modal = this.modalService.open(modalReference, {
  //     size: 'lg',
  //     backdrop: false,
  //     scrollable: true,
  //     backdropClass: 'modal-backdrop',
  //     centered: true
  //   });
  // }




  canFinalizeCount(): boolean {
    const session = this.sessionService.Session;
    const key = session.AccessKeys?.split(',') || [];
    return key?.find(k => k === '14');

  }





  async OnClickFinalizarConteo(): Promise<void> {


    if (!this.canFinalizeCount()) {
      this.alertService.Alert('No cuenta con los permisos para finalizar conteos', Alerts.info, 'modal');
      return;
    }




    let confirmationResult = await this.alertService.Confirmation(
      'Confirmación',
      '¿Desea finalizar el conteo?',
      'Sí'
    );
    if (!confirmationResult) return;



    this.blockUI.start('Procesando..');

    this.CountingService.FinalizeCount(this.currentRevision.IdCount).pipe(finalize(() => { this.blockUI.stop() })).subscribe(response => {

      if (response.Result) {
        this.modalService.dismissAll();
        this.GetRevisions();
        this.alertService.Alert('Conteo finalizado', Alerts.success, 'toast');
      }
    }, error => {
      this.alertService.Alert(AppConstants.GetError(error), Alerts.error, 'modal');
    });





    this.currentRevision.IdCount;

  }



}
