import { Pipe, PipeTransform } from '@angular/core';
import { QueryParameterType } from '../common';

@Pipe({
  name: 'parameterType',
})
export class ParameterTypePipe implements PipeTransform {
  transform(value: string): unknown {
    switch (value) {
      case QueryParameterType.Alpha:
        return 'Alfanumérico';
      case QueryParameterType.Boolean:
        return 'Check';
      case QueryParameterType.Date:
        return 'Fecha';
      case QueryParameterType.MultipleOption:
        return 'Opción múltiple';
      case QueryParameterType.Numeric:
        return 'Numérico';
      default:
        return '';
    }
  }
}
