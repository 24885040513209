import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  enabled: boolean;
  sidebarOpened: boolean;

  constructor() {
    this.enabled = false;
    this.sidebarOpened = false;
  }

  enableSidebar() {
    this.enabled = true;
    this.sidebarOpened = true;
  }

  disableSidebar() {
    this.enabled = false;
    this.sidebarOpened = false;
  }

  toggle() {
    if (!this.enabled) return;

    this.sidebarOpened = !this.sidebarOpened;
  }
}
