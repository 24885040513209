import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, InventoryCounting } from '../models';
import { InventoryCounting2 } from '../models/db/inventory-counting';
import { UserMapping2 } from '../models/db/user-mapping';
import { SessionService } from './session.service';
import { WareHouse } from '../models/other/WareHouse';
import { AssinedTo, Revision } from '../models/other/Revision';
import { ReopenCount } from '../models/other/ReopenCount';
import { AdjustmenResult, AdjustmentHeaderHelper } from '../models/other/AdjustmentHelper';
import { BaseResponse } from '../models/responses/api-response';

@Injectable({
  providedIn: 'root',
})


export class InvCountingsService {
 



  GetCountAdjustment(Id: any) {
    return this.http.get<ApiResponse<AdjustmentHeaderHelper>>(
      `api/InvCountings/GetCountAdjustment?countID=${Id}`,
      {}
    );
  }


  GetUsersProgressForCount(IdCount: number, nRevsion: number) {
    return this.http.get<ApiResponse<AssinedTo[]>>(
      `api/InvCountings/GetCountProgress?countID=${IdCount}&nRevision=${nRevsion}`,
      {}
    );
  }



  AdjustmentProcess(adjustment: AdjustmentHeaderHelper) {
    adjustment.UserMappId = +this.sessionService.Session.UserMappId;
    return this.http.post<ApiResponse<AdjustmenResult>>(
      `api/InvCountings/AdjustmentProcess`,
      adjustment,
      {}
    );
  }


  CreateCountFromAdmin(nuevoConteo: ReopenCount) {
    nuevoConteo.UserMap = +this.sessionService.Session.UserMappId;
    return this.http.post<ApiResponse<any>>(
      `api/InvCountings/CreateCountFromAdmin`,
      nuevoConteo,
      {}
    );
  }
  GetMovDetails(IdConteo: number, nRevision: number) {

    return this.http.get<ApiResponse<any[]>>(
      `api/InvCountings/GetRevisionsDetail?idMov=${IdConteo}&nRevision=${nRevision}`,
      {}
    );
  }
  GetRevisions() {
    return this.http.get<ApiResponse<Revision[]>>(
      `api/InvCountings/GetRevisions`,
      {}
    );
  }

  FinalizeCount(IdCount: number) {
    return this.http.put<ApiResponse<number>>(
      `api/InvCountings/FinalizeCount?idCount=${IdCount}`,
      {}
    );
  }

  
  CloseCounting(id: number, reason: string) {
    return this.http.put<ApiResponse<any>>(
      `api/InvCountings/CloseCount?idCount=${id}&comment=${reason}`,
      {}
    );
  }
  GetWareHouses() {
    return this.http.get<ApiResponse<WareHouse[]>>(
      `api/Warehouses/GetWarehouses`,
      {}
    );
  }
  getAssignedUsers(id: number) {
    return this.http.get<ApiResponse<UserMapping2[]>>(
      `api/InvCountings/GetAssignedUsers?invCountingId=${id}`,
      {}
    );
  }
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { }

  GetInvCountings() {
    return this.http.get<ApiResponse<InventoryCounting[]>>(
      `api/InvCountings/GetInvCountings`,
      {}
    );
  }

  GetInvCounting(invCountingId: number) {
    return this.http.get<ApiResponse<InventoryCounting>>(
      `api/InvCountings/GetInvCounting?invCountingId=${invCountingId}`,
      {}
    );
  }

  PostInvCounting(invCounting: InventoryCounting2) {
    invCounting.UserMappId = +this.sessionService.Session.UserMappId;

    return this.http.post<ApiResponse<InventoryCounting>>(
      `api/InvCountings/PostInvCounting`,
      invCounting,
      {}
    );
  }

  PutInvCounting(invCounting: InventoryCounting2) {
    return this.http.put<ApiResponse<InventoryCounting>>(
      `api/InvCountings/PutInvCounting`,
      invCounting,
      {}
    );
  }

  OpenInvCounting(id: any) {
    return this.http.put<ApiResponse<any>>(
      `api/InvCountings/OpenInvCounting`,
      id,
      {}
    );
  }
}
