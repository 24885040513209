import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, User } from '../models';
import { UserMapping, UserMapping2 } from '../models/db/user-mapping';
import { UserParam } from '../models/other/user-param';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  GetUsers() {
    return this.http.get<ApiResponse<User[]>>(`api/Users/GetUsers`, {});
  }

  GetUser(id: number) {
    return this.http.get<ApiResponse<UserParam>>(
      `api/Users/GetUser?userId=${id}`,
      {}
    );
  }

  PostUser(user: User, userMapping: any) {
    let userParam: UserParam = {
      userData: user,
      userMappingData: userMapping,
    };
    return this.http.post<ApiResponse<UserParam>>(
      `api/Users/PostUser`,
      userParam,
      {}
    );
  }

  PutUser(user: User, userMapping: any) {
    let userParam: UserParam = {
      userData: user,
      userMappingData: userMapping,
    };
    return this.http.put<ApiResponse<UserParam>>(
      `api/Users/PutUser`,
      userParam,
      {}
    );
  }

  StateUser(user: User) {
    return this.http.put<ApiResponse<User>>(`api/Users/StateUser`, user, {});
  }

  GetUserMappings() {
    return this.http.get<ApiResponse<UserMapping2[]>>(
      `api/Users/GetUserMappings`,
      {}
    );
  }
}
