import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map } from 'rxjs/operators';
import { Alerts } from 'src/app/common';
import { countStatus } from 'src/app/common/enums';
import { InventoryCounting, Parameter } from 'src/app/models';
import { OpenCounting } from 'src/app/models/other/OpenCounting';

import {
  AlertService,
  BlockUIService,
  InvCountingsService,
  SessionService,
} from 'src/app/services';

@Component({
  selector: 'app-inv-countings',
  templateUrl: './inv-countings.component.html',
  styleUrls: ['./inv-countings.component.scss'],
})
export class InvCountingsComponent implements OnInit {
  @Input('hiddenOptions') hiddenOptions: any;
  invCountings: InventoryCounting[];
  invCountingNames: string[];
  CopyinvCountings: InventoryCounting[];
  currendCount: InventoryCounting;
  comentarioCierre: string;
  isAssigned: boolean;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private invCountingService: InvCountingsService,
    private alertService: AlertService,
    private blockUI: BlockUIService,
    private sessionService: SessionService
  ) {
    this.invCountings = [];
    this.hiddenOptions = {};
  }

  ngOnInit(): void {
    this.GetInvCountings();
  }


  searchCounting = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? this.GetInvCountings()
        : this.invCountingNames.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )



  onClickSearch() {
    (<HTMLInputElement>document.getElementById('searchInput')).value = '';
    this.GetInvCountings();
  }


  GetInvCountings() {

    this.blockUI.start('Obteniendo conteos, por favor espere...');
    this.invCountingService
      .GetInvCountings()
      .pipe(first())
      .subscribe(
        (response) => {
          //this.alertService.HandleApiResponse(response);
          if (response.Result) {
           
            this.invCountings = response.Data;
            this.invCountingNames = this.invCountings.map(i => i.Name);
            this.CopyinvCountings = [...this.invCountings];
          }
          this.blockUI.stop();
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.HandleUnknownError(err);
        }
      );
  }


  SearchCounting(pParam: any): void {
    if (pParam) {
      let conteo = this.CopyinvCountings.find(i => i.Name === pParam.item)

      if (conteo) {
        this.invCountingService
          .GetInvCounting(conteo.Id)
          .pipe(first())
          .subscribe(
            (response) => {
              if (response.Result) {
                this.invCountings = [];
                this.invCountings.push(response.Data);
              }
            },
            (err) => {
              this.alertService.HandleUnknownError(err);
            }
          );
      }
    }
  }

  async SetCurrendCount(pParam: InventoryCounting) {
    this.currendCount = pParam;
  }






  OnclickAssigCount(): void {
    this.router.navigateByUrl('/assignation/' + this.currendCount.Id);

  }

  OnclickEditCount(): void {
    this.router.navigateByUrl('/inv-counting/' + this.currendCount.Id);
  }


  onClickDeleteInvCounting(s): void {
    //this.router.navigateByUrl('');
  }

  onClickOpenInvCounting(id: number): void {
    // this.blockUI.start('Procesando...');

    // this.invCountingService
    //   .OpenInvCounting(id)
    //   .pipe(first())
    //   .subscribe(
    //     (response) => {
    //       this.blockUI.stop();
    //       this.alertService.HandleApiResponse(response);

    //       if (response.Result) this.GetInvCountings();
    //     },
    //     (err) => {
    //       this.blockUI.stop();
    //       this.alertService.HandleUnknownError(err);
    //     }
    //   );
  }

  canCloseCounting(): boolean {
    const session = this.sessionService.Session;
    const key = session.AccessKeys?.split(',') || [];
    return key?.find(k => k === '11');

  }



  OpenDescriptionModal(modalReference: any, i: any): void {
    this.currendCount = i;
    let modal = this.modalService.open(modalReference, {
      size: 'lg',
      backdrop: false,
      scrollable: true,
      backdropClass: 'modal-backdrop',
      centered: true
    });
  }




  CloseCount(): void {
    if (!this.comentarioCierre || this.comentarioCierre === '') {

      this.alertService.Alert('Ingrese una rason de cierre.', Alerts.warning, 'toast');
      return;
    }

    this.blockUI.start('Cerrando conteo, por favor espere..');
    this.invCountingService.CloseCounting(this.currendCount.Id, this.comentarioCierre).subscribe(result => {
      this.blockUI.stop();
      if (result.Result) {
        this.alertService.Alert('Conteo cerrado exitosamente.', Alerts.success, 'toast');
        this.comentarioCierre = '';
        this.modalService.dismissAll();
        this.GetInvCountings();
      } else {
        this.alertService.Alert('Error al cerrar el conteo, ' + result.ErrorInfo.Message, Alerts.error, 'toast');
      }
    }, err => {
      this.blockUI.stop();
      this.alertService.Alert('Error al cerrar el conteo, ' + err, Alerts.error, 'toast');
    });



  }


  ShowCountDetails(conteo:any){
    if(conteo.Status == countStatus.Accepted){
      this.blockUI.start('Obteniendo información');

      this.invCountingService.GetCountAdjustment(conteo.Id).subscribe(response =>{

        this.blockUI.stop();
        if(response.Result){
          this.alertService.Alert('Preliminar de entrada: '+response.Data.DraftEntryDocNum+' Preliminar de salida: '+response.Data.DraftExitDocNum,Alerts.info,'modal');
        }else{
          this.alertService.Alert('Error '+response.ErrorInfo.Message,Alerts.error,'toast');

        }

        
      },err=>{
        this.blockUI.stop();
        this.alertService.Alert('Error '+err,Alerts.error,'toast');
      });
    }
   

  }



  openModalQueryParams(modalReference: any): void {
    let modal = this.modalService.open(modalReference, {
      size: 'lg',
      backdrop: false,
      scrollable: true,
      backdropClass: 'modal-backdrop',
    });

    modal.result.then(
      () => { },
      (result: OpenCounting) => {
        if (result) {

          this.blockUI.start('Abriendo conteo..');

          result.IdCounting = this.currendCount.Id;
          this.invCountingService.OpenInvCounting(result).subscribe(data => {
            this.blockUI.stop();
            if (data.Result) {
              this.alertService.Alert('Conteo abierto correctamente', Alerts.success, 'toast');
              this.GetInvCountings();
            } else {
              this.alertService.Alert('El conteo no se a podido abrir: ' + data.ErrorInfo.Message, Alerts.warning, 'toast');
            }


          }, err => {
            this.blockUI.stop();
            this.alertService.Alert('El conteo no se a podido abrir: ' + err, Alerts.error, 'modal');
          });
        } else {
          this.blockUI.stop();
          //this.alertService.Alert('Error al parametrizar query, intentelo nuevamente... ', Alerts.warning, 'toast');
        }
      }
    );
  }


  openModalUsersAssigned(modalReference: any): void {
    const modal = this.modalService.open(modalReference, {
      size: 'lg',
      backdrop: false,
      scrollable: true,
      backdropClass: 'modal-backdrop',
      centered: true,
    });
  }

  OnclickOpenModalCloseCount(modalReference: any): void {
    if (!this.canCloseCounting()) {
      this.alertService.Alert('No cuenta con permisos para realizar esta accion.', Alerts.error, 'toast');
      return;
    }
    const modal = this.modalService.open(modalReference, {
      size: 'sm',
      backdrop: false,
      scrollable: true,
      backdropClass: 'modal-backdrop',
      centered: true,
    });
  }

  ShowCloseReason(modalReference: any, i: any): void {
    this.currendCount = i;
    let modal = this.modalService.open(modalReference, {
      size: 'lg',
      backdrop: false,
      scrollable: true,
      backdropClass: 'modal-backdrop',
      centered: true
    });
  }

}
