import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { Alerts, REPORT_PARAMETER } from 'src/app/common/enums';
import { ParameterOption } from 'src/app/models/report-manager/i-parameter-option';
import { Report, Report2 } from 'src/app/models/report-manager/i-report';
import { ReportParameter2 } from 'src/app/models/report-manager/i-report-parameter';
import { ReportUser } from 'src/app/models/report-manager/i-report-user';
import { AlertService, BlockUIService } from 'src/app/services';
import { CommonService } from 'src/app/services/common.service';
import { RptManagerService } from 'src/app/services/rpt-manager.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {


  @ViewChild('tabset') tabset: any;
  active = 1;
  reportForm: UntypedFormGroup;
  parameterForm: UntypedFormGroup;
  parameterValueForm: UntypedFormGroup;

  reportUsers: ReportUser[];
  parameters: ReportParameter2[];
  reports: Report[];
  report: File;
  parameterOptions: ParameterOption[];
  parameterOptionsModal: ParameterOption[];

  parameterSubgroup1: ReportParameter2[];
  parameterSubgroup2: ReportParameter2[];
  parameterSubgroup3: ReportParameter2[];
  parameterSubgroup4: ReportParameter2[];
  paramaterListGroup: ReportParameter2[][];

  constructor(private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private blockUI: BlockUIService,
    private rptManagerService: RptManagerService,
    private alertService: AlertService,
    private commonService: CommonService) { }

  ngOnInit() {
    this.reportForm = this.formBuilder.group({
      Id: 0,
      Name: ['', Validators.required],
      DisplayName: ['', Validators.required],
      ReportUserId: [0, Validators.required],
    });

    this.parameterForm = this.formBuilder.group({
      Id: [0],
      ReportId: [0],
      Name: ['', Validators.required],
      DisplayName: ['', Validators.required],
      Type: [1, Validators.required],
      Required: ['S', Validators.required],
    });

    this.parameterValueForm = this.formBuilder.group({
      Key: ['', Validators.required],
      Value: ['', Validators.required],
    });

    this.initializeApp();
    this.getReportUsers();
    this.getReports();
  }

  initializeApp() {
    this.parameterSubgroup1 = [];
    this.parameterSubgroup2 = [];
    this.parameterSubgroup3 = [];
    this.parameterSubgroup4 = [];
    this.paramaterListGroup = [];
    this.parameters = [];
    this.paramaterListGroup.push(
      this.parameterSubgroup1,
      this.parameterSubgroup2,
      this.parameterSubgroup3,
      this.parameterSubgroup4
    );
    this.parameterOptions = [];
    this.parameterOptionsModal = [];
    this.report = null;
    this.resetParameterForm();
    this.resetReportForm();
    this.resetParameterValueForm();
  }

  resetParameterForm() {
    this.parameterForm.reset({
      Id: 0,
      ReportId: 0,
      Name: '',
      DisplayName: '',
      Type: 1,
      Required: 'S'
    });
  }

  resetReportForm() {
    this.reportForm.reset({
      Id: 0,
      Name: '',
      DisplayName: '',
      ConnectionType: 1,
    });
  }

  resetParameterValueForm() {
    this.parameterValueForm.reset({
      Key: '',
      Value: '',
    });
  }

  getReportUsers() {
    this.blockUI.start('Procesando...');
    this.reportUsers = [];
    this.rptManagerService
      .GetReportUsers()
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();
          if (response.Result) this.reportUsers = response.ReportUsers;
          else
            this.alertService.HandleUnknownError(
              response.ErrorInfo.Message
            );
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  getReports() {
    this.blockUI.start('Procesando...');
    this.reports = [];
    this.rptManagerService
      .GetReports()
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();
          if (response.Result) {
            this.reports = response.Reports;
          } else {
            
            this.alertService.Alert(
              response.ErrorInfo.Message,Alerts.error,'modal'
            );
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  HandlePostOrPutReport(report: Report2) {
    this.blockUI.start('Procesando...');

    this.rptManagerService
      .HandlePostOrPutReport(report)
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.blockUI.stop();

          if (response.Result) {
            this.alertService.Alert('Proceso finalizado exitosamente', Alerts.success, 'toast');
            this.initializeApp();
            this.getReports();
          } else {
            this.alertService.Alert(
              response.ErrorInfo.Message,Alerts.error,'modal'
            );
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  onClickEditReport(report: Report) {
    this.initializeApp();

    this.reportForm.reset({
      Id: report.Id,
      Name: report.Name,
      DisplayName: report.DisplayName,
      ReportUserId: report.ReportUserId,
    });

    this.rptManagerService
      .GetParameters(report.Id)
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result) {
           this.active =2
            if (response.Parameters && response.Parameters.length > 0) {
              response.Parameters.forEach((x) => {
                switch (x.GridCol) {
                  case 0:
                    this.parameterSubgroup1.push(x);
                    break;
                  case 1:
                    this.parameterSubgroup2.push(x);
                    break;
                  case 2:
                    this.parameterSubgroup3.push(x);
                    break;
                  case 3:
                    this.parameterSubgroup4.push(x);
                    break;
                }
              });
            }
          } else {
            this.alertService.HandleUnknownError(
              response.ErrorInfo.Message
            );
          }
        },
        (err) => {
          console.log(err);
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  async onClickSaveChanges() {
    const report = this.getReportModel();

    if (!report.Parameters || report.Parameters.length === 0) {
      const confirmResult = await this.alertService.Confirmation(
        'Confirmación',
        'No has agregado parámetros al reporte. ¿Desea continuar?',
        'Continuar'
      );

      if (!confirmResult) return;
    }

    if (this.report !== null) {
      this.blockUI.start('Procesando...');

      this.rptManagerService
        .SaveReportFile(this.report)
        .pipe(first())
        .subscribe(
          (response: any) => {
            this.blockUI.stop();
            if (response.Result) {
              this.HandlePostOrPutReport(report);
            } else {
              this.alertService.Alert(
                response.ErrorInfo.Message,Alerts.error,'modal'
              );
            }
          },
          (err) => {
            this.blockUI.stop();
            this.alertService.HandleUnknownError(err);
          }
        );
    } else {
      this.HandlePostOrPutReport(report);
    }
  }

  async onClickDeleteParam(paramList: ReportParameter2[], index: number) {
    const confirmResult = await this.alertService.Confirmation(
      'Confirmación',
      '¿Desea eliminar el parámetro?',
      'Eliminar'
    );
    if (confirmResult) paramList.splice(index, 1);
  }

  onClickAddParameter() {
    let parameter = this.getParameterFromForm();
    this.parameterSubgroup1.push(parameter);
    this.resetParameterForm();
    this.resetParameterValueForm();
    this.parameterOptions = null;
  }

  onClickPrintReport(reportId: number) {
    console.log(reportId)
    this.router.navigateByUrl(`report/${reportId}`);
  }


  onClickDownloadFile() {
    if (!this.reportForm.get('Id').value) return;

    const reportName: string = String(this.reportForm.get('Name').value).slice(0, this.reportForm.get('Name').value.length - 4);

    this.blockUI.start('Procesando...');
    this.rptManagerService
      .DownloadFile(Number(this.reportForm.get('Id').value))
      .subscribe((response) => {
        this.blockUI.stop();

        if (response.Result) {
          this.commonService.downloadFile(
            response.Print,
            reportName,
            'application/octet-stream',
            'rpt'
          );
        } else {
          this.alertService.HandleUnknownError(
            response.ErrorInfo.Message
          );
        }
      }, err => {
        this.blockUI.stop();
        this.alertService.HandleUnknownError(
          err
        );
      });
  }

  get reportFormControls() {
    return this.reportForm.controls;
  }

  get parameterFormControls() {
    return this.parameterForm.controls;
  }

  getReportModel() {
    this.parameters = [];
    this.paramaterListGroup.forEach((list, col) => {
      list.forEach((param, row) => {
        param.GridCol = col;
        param.GridRow = row;
        this.parameters.push(param);
      });
    });

    const report: Report2 = {
      Actve: true,
      ApplicationId: 0,
      ReportUserId: Number(this.reportForm.get('ReportUserId').value),
      DisplayName: this.reportForm.get('DisplayName').value,
      Name: this.reportForm.get('Name').value,
      Id: this.reportForm.get('Id').value,
      Parameters: this.parameters,
    };

    return report;
  }

  onReportSelected($event) {
    this.report = <File>$event.target.files[0];

    if (!this.report) return;
    if (!this.commonService.isValidFile(this.report, ['rpt'])) {
      this.report = null;
      this.alertService.Alert(
        'Archivo no soportado', Alerts.warning, 'modal'
      );
      return;
    }

    this.reportForm.get('Name').setValue(this.report.name);
  }

  getParameterFromForm() {
    let parameter: ReportParameter2 = {
      Id: Number(this.parameterForm.get('Id').value),
      Name: this.parameterForm.get('Name').value,
      DisplayName: this.parameterForm.get('DisplayName').value,
      ReportId: Number(this.parameterForm.get('ReportId').value),
      Type: Number(this.parameterForm.get('Type').value),
      GridCol: 0,
      GridRow: 0,
      Options: this.parameterOptions,
      Required: this.parameterForm.get('Required').value === 'S',
    };

    return parameter;
  }

  getParameterType(type) {
    switch (type) {
      case REPORT_PARAMETER.Alpha:
        return 'Alfanumérico';
      case REPORT_PARAMETER.Boolean:
        return 'Check';
      case REPORT_PARAMETER.Date:
        return 'Fecha';
      case REPORT_PARAMETER.Numeric:
        return 'Numérico';
      case REPORT_PARAMETER.MultipleOption:
        return 'Opción múltiple';
      default:
        return '';
    }
  }

  //SDK D&D
  onItemDropped(event) {
    // if (event.previousContainer === event.container) {
    //   moveItemInArray(
    //     event.container.data,
    //     event.previousIndex,
    //     event.currentIndex
    //   );
    // } else {
    //   transferArrayItem(
    //     event.previousContainer.data,
    //     event.container.data,
    //     event.previousIndex,
    //     event.currentIndex
    //   );
    // }
  }


  dismissModal(result: boolean) {
    this.modalService.dismissAll(result);
  }

  onTabChange($event) {
    // console.log($event)
    // if ($event.nextId === 'tabList') {
       this.initializeApp();
    // }
  }

  showParameterOptionsModal(
    modalParameterOptions: any,
    options: ParameterOption[]
  ) {
    this.parameterOptionsModal = options;
    let modal = this.modalService.open(modalParameterOptions, {
      size: 'lg',
      backdrop: true,
    });

    modal.result.then(
      () => { },
      () => {
        options = this.parameterOptionsModal;
        this.parameterOptionsModal = null;
        console.log(options);
        console.log(this.parameterOptions);
      }
    );
  }

  onParameterTypeChange(event: any, modalParameterOptions: any) {
    if (Number(event.target.value) === REPORT_PARAMETER.MultipleOption) {
      if (!this.parameterOptions) this.parameterOptions = [];

      this.showParameterOptionsModal(
        modalParameterOptions,
        this.parameterOptions
      );
    }
  }

  onClickParameterOptions(
    parameter: ReportParameter2,
    modalParameterOptions: any
  ) {
    this.showParameterOptionsModal(modalParameterOptions, parameter.Options);
  }

  onClickAddParameterValidValue() {
    this.parameterOptionsModal.push({
      ...this.parameterValueForm.value,
      Id: 0,
      ParameterId: 0,
    });
    this.resetParameterValueForm();
  }

  onClickDeleteValidValue(index: number) {
    if (this.parameterOptionsModal.length === 1) {
      this.alertService.Alert(
        'Debes agregar al menos una opción válida', Alerts.info, 'modal'
      );
      return;
    }

    this.parameterOptionsModal.splice(index, 1);
  }


}
