<block-ui>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row py-2 border-bottom">
          <div class="col-4 col-md-2">
            <h4 class="text-truncate" title="Todos los queries">
              <!-- Todos los queries -->
              
            </h4>
          </div>
          <div class="col-8 col-md-6">
            <input id="searchInput" type="search" (selectItem)="selectedQueri($event)" [ngbTypeahead]="searchQuery"
              class="form-control form-control-sm" placeholder="Nombre o descripción..." />
          </div>
          <div class="col-12 col-md-4">
            <button (click)="onClickFindQueries()" class="btn btn-sm btn-light"><i class="fa fa-search"
                aria-hidden="true"></i>Buscar</button>
            <div class="d-flex flex-row-reverse">
              <div class="form-group mb-0">
                <button class="form-control form-control-sm btn-light shadow-sm btn-st" (click)="onClickNewQuery()">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  Agregar Query
                </button>
              </div>
            </div>
          </div>
        </div>


        <!-- Añadido por alejandro - 01-->
        <div class="col-12 mt-2">
          <div class="tableFixHead mt-2">
            <table class="table table-striped table-sm table-responsive-lg table-bordered table-hover">
              <thead style="background-color: rgb(128,188,91); color: white;">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Descripcion</th>
                  <th scope="col">Fecha de creación</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Activo</th>
                  <th scope="col">Opc</th>


                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let qry of queries; let i = index" class="p-0">
                  <th scope="row">{{(i + 1)}}</th>


                  <td (click)="RiseQueryParams(content,qry.Id)" class="form-group input-group-sm ml-auto mr-auto ">
                    <strong class="RiseParams">{{qry.Name}}</strong>
                  </td>


                  <!-- <td>{{qry.Name}}</td> -->
                  <td>{{qry.Description}}</td>
                  <td>{{qry.CreateDate | date:'yyyy/MM/dd'}}</td>
                  <td>{{qry.ObjectType=='P'?'Procedimiento':'Vista'}}</td>
                  <td class="text-center"><i class="fa fa-{{qry.Active ==='Y'? 'check': 'times'}}"></i></td>



                  <td class="justify-content-center option clasesita">
                    <i class="fa fa-edit" style="cursor: pointer;" title="Editar query"
                      (click)="onClickEditQuery(qry)"></i>
                    <i class="fa fa-trash" style="cursor: pointer;" title="Eliminar query"
                      (click)="onClickDeleteQuery(qry)"></i>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--Fin 01-->
          <!-- Modal parametros - 02-->

          <ng-template #content let-modal>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabelll"><i class="fas fa-1x fa-info-circle "></i>
                {{selectedQuery.Name}} </h5>
              <button type="button" class="close " style="outline:none;" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-2">
              <h4>Parametros</h4>
              <div class="container">
                <div class="tableFixHead mt-2">
                  <table class="table table-striped mt table-sm table-hover">

                    <thead style="background-color: rgb(128,188,91); color: white;">
                      <tr>
                        <th scope="col" style="width: 5%;">#</th>
                        <th scope="col" style="width: 25%;">Nombre</th>
                        <th scope="col" style="width: 25%;">Tipo</th>
                        <th scope="col" style="width: 25%;">Requerido</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let param of queryParams; let i = index" class="p-0">
                        <td scope="row">{{(i + 1)}}</td>
                        <td>{{ param.Name }}</td>
                        <td>{{ param.Type | parameterType }}</td>
                        <td><i class="fa fa-{{param.Required=='Y' ? 'check': 'times'}}"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>

            </div>
            <div class="modal-footer">
              <div class="col">
                <button (click)="modal.dismiss('Cross click')" type="button" class="btn  btn-sm" data-dismiss="modal"><i
                    class="fas fa-check"></i>
                  Aceptar
                </button>
              </div>
            </div>

          </ng-template>


          <!--Fin modal parametros 02-->

          <!-- <div class="col-12 mt-2">
        <div class="row my-2" [ngClass]="{ 'border-bottom': i !== queries.length - 1 }"
          *ngFor="let query of queries; index as i">
          <div class="col-8">
            <h6>{{ query.Name }}</h6>
            <p>{{ query.Description }}</p>
          </div>
          <div class="col-4 text-center">
            <div>
              <button type="button" class="btn btn-sm btn-primary mx-1" (click)="onClickEditQuery(query)">
                Editar
              </button>
              <button type="button" class="btn btn-sm btn-primary" (click)="onClickDeleteQuery(query)">
                Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
 -->



        </div>
      </div>
    </div>
  </div>

</block-ui>