import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { Permission, Rol, User } from 'src/app/models';
import { UserParam } from 'src/app/models/other/user-param';
import { AlertService, UsersService } from 'src/app/services';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RolsService } from 'src/app/services/rols.service';

class PermissionCheck {
  permission: Permission;
  isSelected: boolean;
}

@Component({
  selector: 'app-permission-rols',
  templateUrl: './permission-rols.component.html',
  styleUrls: ['./permission-rols.component.scss']
})
export class PermissionRolsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
}
