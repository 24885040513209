<div class="container-fluid shadow">
  <div class="row border-bottom mb-2">
    <div class="col-12">
      <div class="py-4 px-1">
        <h6>Queries disponibles</h6>
        <button type="button" class="close " style="outline:none;" data-dismiss="modal" aria-label="Close"
          (click)="onClickCancel()">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12" [formGroup]="queryForm">
      <div class="row">

        <div class="paramRowContainer paramRow1">
          <div class="form-group">
            <label>Seleccione un query. <span class="text-danger">*</span></label>

            <select class="form-control form-control-sm mt-1" [ngClass]="{
                      'is-invalid':
                        formControls('queryForm').QueryId.errors &&
                        formControls('queryForm').QueryId.touched
                    }" formControlName="QueryId" (change)="onChangeQuery()">
              <option *ngFor="let query of queries" [value]="query.Id">
                {{ query.Name }}
              </option>
            </select>
            <div *ngIf="formControls('queryForm').QueryId.errors" class="invalid-feedback">
              <small *ngIf="
                        formControls('queryForm').QueryId.errors.required
                      ">
                Este campo es requerido
              </small>
            </div>
          </div>
        </div>

        <div class="paramRowContainer paramRow2">
          <div class="row">
            <div class="col-12">
              <label>Lista de parámetros</label>
            </div>
            <div class="col-12" *ngIf="parameters.length === 0">
              <label>El objeto seleccionado no posee
                parámetros...</label>
            </div>

            <div class="paramConteiner paramRow2">

              <div  class="accordion" id="accordion">
                <div class="card"   [ngSwitch]="parameter.Type" (click)="OnClickAcordeonRow($event)" *ngFor="let parameter of parameters; index as i">
                  <div class="card-header" [id]="'heading' + parameter.Id">
                    <h2 class="mb-0">
                      <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" [attr.data-target]="'#collapse' + parameter.Id" aria-expanded="false" [attr.aria-controls]="'collapse' + parameter.Id">
                        {{ parameter.Name }}<span class="text-danger" *ngIf="parameter.Required === 'Y'">*</span>
                      </button>
                    </h2>
                  </div>
                  <div [attr.id]="'collapse' + parameter.Id" class="collapse show" [attr.aria-labelledby]="'heading' + parameter.Id" data-parent="#accordion">
                    <div class="card-body">

                      <input type="text" class="form-control form-control-sm" [ngClass]="{
                                            'is-invalid':
                                              formControls('queryForm')[parameter.Name]
                                                .errors &&
                                              formControls('queryForm')[parameter.Name]
                                                .touched
                                          }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'A'" />

                      <input type="number" class="form-control form-control-sm" [ngClass]="{
                                            'is-invalid':
                                              formControls('queryForm')[parameter.Name]
                                                .errors &&
                                              formControls('queryForm')[parameter.Name]
                                                .touched
                                          }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'N'" />

                      <input type="date" class="form-control form-control-sm" [ngClass]="{
                                            'is-invalid':
                                              formControls('queryForm')[parameter.Name]
                                                .errors &&
                                              formControls('queryForm')[parameter.Name]
                                                .touched
                                          }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'D'" />

                      <input type="checkbox" class="form-control form-control-sm" [ngClass]="{
                                            'is-invalid':
                                              formControls('queryForm')[parameter.Name]
                                                .errors &&
                                              formControls('queryForm')[parameter.Name]
                                                .touched
                                          }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'B'" />

                      <div *ngSwitchCase="'M'" class="multiSelectBody">

                        <app-multi-select-options [title]="'Opciones'" [list]="parameter.ValidValues"
                                                  (shareCheckedList)="shareCheckedList($event)"
                                                  (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
                        </app-multi-select-options>

                      </div>


                    </div>
                  </div>
                </div>
              </div>



              <!--<ngb-accordion  [destroyOnHide]="false" [closeOthers]="true"  #acc="ngbAccordion" (panelChange)="OnClickAcordeonRow($event)">
                <ngb-panel style="cursor: pointer;" *ngFor="let parameter of parameters; index as i" id="toggle-{{i}}"
                  [ngSwitch]="parameter.Type">

                  <ng-template ngbPanelTitle style="cursor: pointer;">
                    <label>{{ parameter.Name }}<span class="text-danger"
                        *ngIf="parameter.Required ==='Y'">*</span></label>
                  </ng-template>

                  &lt;!&ndash;Contenido&ndash;&gt;
                  <ng-template ngbPanelContent>

                    <input type="text" class="form-control form-control-sm" [ngClass]="{
                                            'is-invalid':
                                              formControls('queryForm')[parameter.Name]
                                                .errors &&
                                              formControls('queryForm')[parameter.Name]
                                                .touched
                                          }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'A'" />

                    <input type="number" class="form-control form-control-sm" [ngClass]="{
                                            'is-invalid':
                                              formControls('queryForm')[parameter.Name]
                                                .errors &&
                                              formControls('queryForm')[parameter.Name]
                                                .touched
                                          }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'N'" />

                    <input type="date" class="form-control form-control-sm" [ngClass]="{
                                            'is-invalid':
                                              formControls('queryForm')[parameter.Name]
                                                .errors &&
                                              formControls('queryForm')[parameter.Name]
                                                .touched
                                          }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'D'" />

                    <input type="checkbox" class="form-control form-control-sm" [ngClass]="{
                                            'is-invalid':
                                              formControls('queryForm')[parameter.Name]
                                                .errors &&
                                              formControls('queryForm')[parameter.Name]
                                                .touched
                                          }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'B'" />

                    <div *ngSwitchCase="'M'" class="multiSelectBody">

                      <app-multi-select-options [title]="'Opciones'" [list]="parameter.ValidValues"
                        (shareCheckedList)="shareCheckedList($event)"
                        (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
                      </app-multi-select-options>

                    </div>

                  </ng-template>

                </ngb-panel>

              </ngb-accordion>-->

              <!-- <div class="col-12 " *ngFor="let parameter of parameters" [ngSwitch]="parameter.Type">
                                <div class="form-group">
                                    <label>
                                        {{ parameter.Name }}
                                        <span class="text-danger" *ngIf="parameter.Required ==='Y'">
                                            *
                                        </span>
                                    </label>

                                    <input type="text" class="form-control form-control-sm" [ngClass]="{
                          'is-invalid':
                            formControls('queryForm')[parameter.Name]
                              .errors &&
                            formControls('queryForm')[parameter.Name]
                              .touched
                        }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'A'" />


                                    <input type="number" class="form-control form-control-sm" [ngClass]="{
                          'is-invalid':
                            formControls('queryForm')[parameter.Name]
                              .errors &&
                            formControls('queryForm')[parameter.Name]
                              .touched
                        }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'N'" />


                                    <input type="date" class="form-control form-control-sm" [ngClass]="{
                          'is-invalid':
                            formControls('queryForm')[parameter.Name]
                              .errors &&
                            formControls('queryForm')[parameter.Name]
                              .touched
                        }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'D'" />


                                    <input type="checkbox" class="form-control form-control-sm" [ngClass]="{
                          'is-invalid':
                            formControls('queryForm')[parameter.Name]
                              .errors &&
                            formControls('queryForm')[parameter.Name]
                              .touched
                        }" formControlName="{{ parameter.Name }}" *ngSwitchCase="'B'" />



                                    <app-multi-select-options *ngSwitchCase="'M'" [title]="'Opciones'"
                                        [list]="parameter.ValidValues" (shareCheckedList)="shareCheckedList($event)"
                                        (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
                                    </app-multi-select-options>

                                </div>
                            </div> -->


            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
  <div class="row border-top mt-2">
    <div class="col-12">
      <div class="py-4 px-1 d-flex justify-content-end">
        <div class="d-flex flex-row-reverse">
          <div class="form-group mb-0">
            <button (click)="onClickCancel()" class="form-control form-control-sm btn-light shadow-sm btn-st">
              <i class="fa fa-ban" aria-hidden="true"></i>
              Cancelar
            </button>
          </div>
        </div>

        <div class="d-flex flex-row-reverse">
          <div class="form-group mb-0">
            <button (click)="onClickAccept()" class="form-control form-control-sm btn-light shadow-sm btn-st">
              <i class="fa fa-plus" aria-hidden="true"></i>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
