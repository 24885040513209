import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { Rol, User } from 'src/app/models';
import { UserParam } from 'src/app/models/other/user-param';
import { AlertService, UsersService } from 'src/app/services';
import { RolsService } from 'src/app/services/rols.service';

@Component({
  selector: 'app-rols',
  templateUrl: './rols.component.html',
  styleUrls: ['./rols.component.scss']
})
export class RolsComponent implements OnInit {

  users: User[];
  rols: Rol[];

  userSelected: UserParam;
  rolSelected: Rol;

  sendRol: Rol;

  constructor(
    private usersService: UsersService,
    private rolsService: RolsService,
    private alertService: AlertService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.users = [];
    this.rols = [];
    this.sendRol = null;
    this.loadData()
  }

  loadData(){
    this.getUsers();
    this.getRols();
  }

  getUsers(){
    this.usersService
      .GetUsers()
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result){
            this.users = [];
            response.Data.forEach(x => {if (x.Active) this.users.push(x)} )
          }
          if (this.users.length > 0) this.onSelectUser(this.users[0])
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  getRols(){
    this.rolsService
      .GetRols()
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result) this.rols = response.Data;
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      );
  }

  onNewRol(modalReference: any, rol: Rol){
    this.sendRol = rol;

    let modal = this.modalService.open(modalReference, {
      size: 'lg',
      backdrop: false,
      scrollable: true,
      backdropClass: 'modal-backdrop',
    });

    modal.result.then(
      () => {},
      (result: Rol) => {
        if (result) {
          if(result.Id === -1){
            this.rolsService.PostRol(result).subscribe(res => {
              if(res.Result) {
                this.getRols()
                this.onSelectUser(this.userSelected.userData)
              }
            }, err =>{
              this.alertService.HandleUnknownError(err);
            });
          } else {
            this.rolsService.PutRol(result).subscribe(res => {
              if(res.Result) {
                this.getRols()
                this.onSelectUser(this.userSelected.userData)
              }
            }, err =>{
              this.alertService.HandleUnknownError(err);
            });
          }
        }
      }
    );
  }

  onSelectUser(user: User){
    if(user){
    this.usersService
      .GetUser(user.Id)
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result) this.userSelected = response.Data
          this.onSelectRol(this.rols.find(x => x.Id == this.userSelected.userMappingData.RolId))
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      );
    }
  }

  onSelectRol(rol: Rol){
    if(rol){
      if(rol.Active){
      this.rolsService
        .GetRol(rol.Id)
        .pipe(first())
        .subscribe(
          (response) => {
            if (response.Result) this.rolSelected = response.Data
            this.updateUserRol();
          },
          (err) => {
            this.alertService.HandleUnknownError(err);
          }
        );
      } else {
        this.alertService.Alert('El rol está inactivo, no se puede asignar', 'error', 'toast')
      }
    }
  }

  updateUserRol(){
    if(this.userSelected.userMappingData.RolId != this.rolSelected.Id){
      this.userSelected.userMappingData.RolId = this.rolSelected.Id
      this.usersService.PutUser(this.userSelected.userData, this.userSelected.userMappingData)
      .pipe(first())
      .subscribe(
        (response) => {
          if(response.Result) this.alertService.Alert('Rol de usuario actualizado', 'info', 'toast')
        },
        (err) => {
          this.alertService.HandleUnknownError(err);
        }
      );
    }
  }

}
