import { Injectable } from '@angular/core';
import swal, { SweetAlertOptions } from 'sweetalert2';
import Swal from 'sweetalert2';
import { Alerts } from '../common';
import { ApiResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  HandleApiResponse(response: ApiResponse<any>) {
    if (response.Result)
      this.Alert('Proceso finalizado exitosamente', Alerts.success, 'toast');
    else
      this.Alert(
        response.ErrorInfo?.Message || 'Algo ha salido mal',
        Alerts.error,
        'modal'
      );
  }

  HandleUnknownError(error: any) {
    let err = error ? (error.message ? error.message : '') : '';
    this.Alert(`Algo ha salido mal. ${err}`, Alerts.error, 'modal');
  }

  Alert(
    message: string,
    icon: 'warning' | 'error' | 'success' | 'info' | 'question',
    type: 'toast' | 'modal'
  ) {
    if (type === 'toast') this.Toast(message, icon);
    else this.Modal(message, icon);
  }

  async Confirmation(titulo: string, msg: string, confirmButtonText: string) {
    let settings: SweetAlertOptions = {
      title: titulo,
      text: msg,
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#007bff',
      confirmButtonText,
    };

    return Swal.fire(settings).then((result) => {
      return result.isConfirmed;
    });
  }

  private Toast(text: string, icon: any) {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
    });

    toast.fire({
      icon,
      text: text,
    });
  }

  private Modal(text: string, icon: any) {
    swal.fire({
      icon,
      text,
    });
  }
}
