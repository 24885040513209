import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../common';

import { SessionService } from './session.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url.includes('rptmanager')) {
      return next.handle(request);
    }

    if (request.url.includes('api/')) {
      const request2 = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.sessionService.Session.access_token}`,
        },
        url: `${AppConstants.ApiUrl}${request.url}`,
      });

      return next.handle(request2);
    }

    return next.handle(request);
  }
}
