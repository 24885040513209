<div class="container-fluid shadow">
  <div class="row border-bottom mb-2">
    <div class="col-12">
      <div class="py-4 px-1">
        <h6 >{{tittle}}</h6>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="row" [formGroup]="parameterForm">
        <div class="col-12">
          <div class="form-group">
            <label for="Name">Nombre</label>
            <input type="text" id="Name" formControlName="Name" class="form-control form-control-sm" [ngClass]="{
                'is-invalid':
                  parameterFormControls.Name.errors &&
                  parameterFormControls.Name.touched
              }" />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="Required">Requerido</label>
            <select formControlName="Required" class="form-control form-control-sm" [ngClass]="{
                'is-invalid':
                  parameterFormControls.Required.errors &&
                  parameterFormControls.Required.touched
              }">
              <option value="Y">Si</option>
              <option value="N">No</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="Type">Tipo</label>
            <select formControlName="Type" class="form-control form-control-sm" [ngClass]="{
                'is-invalid':
                  parameterFormControls.Type.errors &&
                  parameterFormControls.Type.touched
              }" (change)="onChangeParameterType()">
              <option value="A">Alfanumérico</option>
              <option value="N">Numérico</option>
              <option value="B">Check</option>
              <option value="D">Fecha</option>
              <option value="M">Opción multiple</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 border-left">
      <div class="container-fluid">
        <div class="row" [formGroup]="validValueForm">
          <div class="col-5">
            <div class="form-group">
              <label>Opción</label>
              <input type="text" formControlName="Option" class="form-control form-control-sm" [ngClass]="{
                  'is-invalid':
                    optionFormControls.Option.errors &&
                    optionFormControls.Option.touched
                }" />
            </div>
          </div>
          <div class="col-5">
            <div class="form-group">
              <label>Valor</label>
              <input type="text" formControlName="Value" class="form-control form-control-sm" [ngClass]="{
                  'is-invalid':
                    optionFormControls.Value.errors &&
                    optionFormControls.Value.touched
                }" />
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label>&nbsp;</label>
              <button class="btn btn-sm" (click)="onClickAddValidValue()">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row" style="max-height: 300px; overflow-y: auto">
          <div class="col-12 border my-1 rounded" *ngIf="validValues.length === 0">
            <p>No has agregado opciones válidas...</p>
          </div>
          <div class="col-12 border my-1 rounded" *ngFor="let option of validValues; index as i">
            <div class="container-fluid">
              <div class="row">
                <div class="col-8">
                  <h6>{{ option.Option }}</h6>
                  <p>Valor: {{ option.Value }}</p>
                </div>
                <div class="col-4 d-flex align-items-center justify-content-center">
                  <button class="btn btn-sm btn-danger" (click)="onClickDeleteValidOption(i)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row border-top mt-2">
    <div class="col-12">
      <div class="py-4 px-1 d-flex justify-content-end">
        <button class="btn btn-sm btn-danger mx-1" (click)="onClickCancel()">
          Cancelar
        </button>
        <button class="btn btn-sm btn-success mx-1" (click)="onClickAccept()">
          Aceptar
        </button>
      </div>
    </div>
  </div>
</div>