<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="row py-2 border-bottom">
        <div class="col-6">
          <h4>{{tittle}}</h4>

        </div>
        <div class="col-6 text-right">
          <button class="btn btn-sm btn-ligh" routerLink="/queries">
            <i class="fa fa-ban" aria-hidden="true"></i>
            Descartar
          </button>
          <!-- <a routerLink="/queries" class="btn mx-1 btn-sm btn-danger">Descartar</a> -->
          <button class="btn btn-sm btn-ligh" (click)="onClickSaveChanges()">
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            Guardar
          </button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-md-8" [formGroup]="queryForm">
          <div class="row">
            <div class="col-8">
              <div class="form-group">
                <label>Nombre <span class="text-danger">*</span></label>
                <input type="text" class="form-control form-control-sm" formControlName="Name" [ngClass]="{
                    'is-invalid':
                      formControls.Name.errors && formControls.Name.touched
                  }" />
                <div *ngIf="formControls.Name.errors" class="invalid-feedback">
                  <small *ngIf="formControls.Name.errors.required">
                    Este campo es requerido
                  </small>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Tipo de objeto <span class="text-danger">*</span></label>
                <select class="form-control form-control-sm" formControlName="ObjectType" [ngClass]="{
                    'is-invalid':
                      formControls.ObjectType.errors &&
                      formControls.ObjectType.touched
                  }">
                  <option value="V">Vista</option>
                  <option value="P">Procedimiento</option>
                </select>
                <div *ngIf="formControls.ObjectType.errors" class="invalid-feedback">
                  <small *ngIf="formControls.ObjectType.errors.required">
                    Este campo es requerido
                  </small>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Descripción <span class="text-danger">*</span></label>
                <textarea rows="10" class="form-control form-control-sm" formControlName="Description" [ngClass]="{
                    'is-invalid':
                      formControls.Description.errors &&
                      formControls.Description.touched
                  }">
                </textarea>
                <div *ngIf="formControls.Description.errors" class="invalid-feedback">
                  <small *ngIf="formControls.Description.errors.required">
                    Este campo es requerido
                  </small>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Activo</label>
                <select class="form-control form-control-sm" formControlName="Active" [ngClass]="{
                    'is-invalid':
                      formControls.Active.errors && formControls.Active.touched
                  }">
                  <option value="Y">Si</option>
                  <option value="N">No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 ">
          <div class="row ">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-7 col-form-label">Lista de parámetros</label>
                <div class="col-5">
                  <!-- <button class="btn btn-sm btn-primary btn-block mt-2" (click)="onClickAddParameter(queryParameterModalContainer)">
                    Nuevo
                  </button> -->
                  <div class="d-flex flex-row-reverse">
                    <div class="form-group mb-0">
                      <button type="button" class="btn  btn-sm"
                        (click)="onClickAddParameter(queryParameterModalContainer)">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        Nuevo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 my-2" *ngIf="parameters.length === 0">
              <div class="container-fluid border rounded">
                <div class="row">
                  <div class="col-12">
                    <p>No has agregado parámetros...</p>
                  </div>
                </div>
              </div>
            </div>


            <div class="paramListContainer">
              <div class="col-12 my-2" *ngFor="let param of parameters; index as i">
                <div *ngIf="!param.IsRemoved" class="container-fluid border rounded paramContenedor"
                  [ngClass]="{ 'border-primary': param.Selected }">
                  <div class="row">
                    <div class="col-10">
                      <h6>{{ param.Name }}</h6>
                      <p>Tipo: {{ param.Type | parameterType }}</p>
                    </div>
                    <div class="col-2 d-flex align-items-center justify-content-center">
                      <div>
                        <button type="button" title="Modificar" class="btn btn-sm  mx-1"
                          (click)="OnClickUpdateParameter(queryParameterModalContainer,param)">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button type="button" title="Eliminar" class="btn btn-sm  mx-1"
                          (click)="onClickDeleteParameter(i)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #queryParameterModalContainer>
  <app-query-parameter [paramToEdit]="currenParam"></app-query-parameter>
</ng-template>
