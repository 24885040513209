import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '../common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  Login(email: string, password: string) {
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('UserName', email)
      .set('Password', password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<any>(`${AppConstants.ApiUrl}token`, body.toString(), {
      headers,
    });
  }
}
