import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SidebarModule } from 'ng-sidebar';
import { BlockUIModule } from 'ng-block-ui';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { QueriesComponent } from './components/query/queries/queries.component';
import { NewQueryComponent } from './components/query/new-query/new-query.component';
import { QueryParameterComponent } from './components/query/query-parameter/query-parameter.component';
import { RequestInterceptor } from './services/request-interceptor';
import { LoginComponent } from './components/login/login.component';
import { InvCountingsComponent } from './components/inv-counting/inv-countings/inv-countings.component';
import { ParameterTypePipe } from './pipes/parameter-type.pipe';
import { NewCountingComponent } from './components/inv-counting/new-counting/new-counting.component';
import { AssignationComponent } from './components/inv-counting/assignation/assignation.component';
import { UsersComponent } from './components/user/users/users.component';
import { NewUserComponent } from './components/user/new-user/new-user.component';
import { NewPermissionComponent } from './components/premission/new-permission/new-permission.component';
import { PermissionsComponent } from './components/premission/permissions/permissions.component';
import { NewRolComponent } from './components/premission/new-rol/new-rol.component';
import { RolsComponent } from './components/premission/rols/rols.component';
import { PermissionRolsComponent } from './components/premission/permission-rols/permission-rols.component';
import { DaysDiffPipe } from './pipes/days-diff.pipe';
import {QryParamsComponent} from './components/inv-counting/inv-countings/qry-params/qry-params.component';
import { AssignedUsersComponent } from './components/inv-counting/inv-countings/assigned-users/assigned-users.component';
import { StatusTypePipe } from './pipes/status-type.pipe';
import { RevisionComponent } from './components/inv-counting/revision/revision.component';
import { UserProgressPipe } from './pipes/user-progress.pipe';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { ReportsComponent } from './components/reports/reports.component';
import { PrintReportComponent } from './components/print-report/print-report.component';
import { MultiSelectOptionsComponent } from './components/multi-select-options/multi-select-options.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    QueriesComponent,
    NewQueryComponent,
    QueryParameterComponent,
    LoginComponent,
    InvCountingsComponent,
    ParameterTypePipe,
    NewCountingComponent,
    AssignationComponent,
    UsersComponent,
    NewUserComponent,
    NewPermissionComponent,
    PermissionsComponent,
    NewRolComponent,
    RolsComponent,
    PermissionRolsComponent,
    DaysDiffPipe,
    QryParamsComponent,
    AssignedUsersComponent,
    StatusTypePipe,
    RevisionComponent,
    UserProgressPipe,
    MultiSelectDropdownComponent,
    ReportsComponent,
    PrintReportComponent,
    MultiSelectOptionsComponent,
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SidebarModule.forRoot(),
    BlockUIModule.forRoot(),
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    DragDropModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
